import { Button, Form, FormProps, Input, Modal } from 'antd'
import { FC, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { CheckMarkIcon } from '../../elements/svg_icons/CheckMarkIcon'
import { ModuleExerciseDto, ModulesDto } from '../../../shared/types/api'
import * as API_COURSES from '../../../shared/http/courses'


interface AddAICardListDialogProps {
    isOpen: boolean,
    close: () => void,
    cModule: ModulesDto,
    addExercise: (ex: ModuleExerciseDto) => void
}

const CreateCourseTestDialog: FC<AddAICardListDialogProps> = ({ isOpen, close, addExercise, cModule }) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(undefined)
    const [form] = Form.useForm()

    type FieldType = {
        title?: string;
        answer0?: string;
        answer1?: string;
        answer2?: number;
        answer3?: string;
        comment?: string;
    };

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        setLoading(true)
        const ex =
            {
                type: 'TEST', moduleId: cModule.id, data:
                    {
                        title: values.title,
                        comment: values.comment,
                        answers: [
                            { value: values.answer0, correct: selectedAnswer === 0 },
                            { value: values.answer1, correct: selectedAnswer === 1 },
                            { value: values.answer2, correct: selectedAnswer === 2 },
                            { value: values.answer3, correct: selectedAnswer === 3 }
                        ]
                    }
            }

        API_COURSES.addExercise(ex as ModuleExerciseDto).then(resp => {
            addExercise(resp as ModuleExerciseDto)
        }).finally(() => {
            setLoading(false)
            clearAndClose()
        })
    }


    const clearAndClose = () => {
        form.resetFields()
        setSelectedAnswer(undefined)
        close()
    }

    return (
        <Modal title='Создание теста в курсе'
               onCancel={clearAndClose}
               footer={[]}
               open={isOpen}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete='off'
                onFinish={onFinish}
            >

                <Form.Item<FieldType>
                    name='title'
                    label={'Заголовок'}
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                >
                    <TextArea maxLength={600} rows={2}

                              placeholder={'Заголовок теста'} />

                </Form.Item>

                <Form.Item<FieldType>
                    name='answer0'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Первый вариант ответа'}
                >


                    <Input addonAfter={
                        <CheckMarkIcon stroke={selectedAnswer === 0 ? 'green' : 'red'} onClick={() => {
                            setSelectedAnswer(0)
                        }} />
                    } />


                </Form.Item>


                <Form.Item<FieldType>
                    name='answer1'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Второй вариант ответа'}
                >
                    <Input addonAfter={
                        <CheckMarkIcon stroke={selectedAnswer === 1 ? 'green' : 'red'} onClick={() => {
                            setSelectedAnswer(1)
                        }} />
                    } />

                </Form.Item>

                <Form.Item<FieldType>
                    name='answer2'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Третий вариант ответа'}
                >
                    <Input addonAfter={
                        <CheckMarkIcon stroke={selectedAnswer === 2 ? 'green' : 'red'} onClick={() => {
                            setSelectedAnswer(2)
                        }} />
                    } />

                </Form.Item>


                <Form.Item<FieldType>
                    name='answer3'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Четвертый вариант ответа'}
                >
                    <Input addonAfter={
                        <CheckMarkIcon stroke={selectedAnswer === 3 ? 'green' : 'red'} onClick={() => {
                            setSelectedAnswer(3)
                        }} />
                    } />

                </Form.Item>


                <Form.Item<FieldType>
                    name='comment'
                    label={'Комментарий к тесту. Отображается после выбора ответа'}
                >
                    <Input />
                </Form.Item>


                <Form.Item wrapperCol={{ offset: 9, span: 8 }}>
                    <Button loading={loading} type='primary' htmlType='submit' disabled={selectedAnswer === undefined}>
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateCourseTestDialog
