import { Button, Form, FormProps, Modal, Upload } from 'antd'
import { FC, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { UploadOutlined } from '@ant-design/icons'
import { ModuleExerciseDto, ModulesDto } from '../../../shared/types/api'
import { ExerciseModuleType } from '../../../common/enums/exercise-module-type'
import * as API_COURSES from '../../../shared/http/courses'
import { addAudiExercise } from '../../../shared/http/courses'


interface CreateCourseAudioDialogProps {
    isOpen: boolean,
    close: () => void,
    cModule: ModulesDto,
    addExercise: (ex: ModuleExerciseDto) => void
}

const CreateCourseAudioDialog: FC<CreateCourseAudioDialogProps> = ({ isOpen, close, addExercise, cModule }) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [file, setFile] = useState<any>(undefined)
    const [form] = Form.useForm()

    type FieldType = {
        comment: string;
    };

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        setLoading(true)
        API_COURSES.addAudiExercise(file, cModule.id, values.comment).then(resp => {
            addExercise(resp.data as ModuleExerciseDto)
        }).finally(() => {
            setLoading(false)
            clearAndClose()
        })

    }

    const uploadFile = (info: any) => {
        info.onSuccess()
        setFile(info.file)
    }


    const clearAndClose = () => {
        form.resetFields()
        setFile(undefined)
        close()
    }

    return (
        <Modal title='Создание аудио в курсе'
               onCancel={clearAndClose}
               footer={[]}
               open={isOpen}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete='off'
                onFinish={onFinish}
            >

                <Form.Item<FieldType>
                    name='comment'
                    label={'Комментарий'}
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                >
                    <TextArea maxLength={600} rows={2}
                              placeholder={'Комментарий к аудио'} />

                </Form.Item>

                <Upload style={{ margin: 26 }} customRequest={uploadFile}>
                    <Button icon={<UploadOutlined />}>Загрузите аудио</Button>
                </Upload>


                <Form.Item wrapperCol={{ offset: 9, span: 8 }}>
                    <Button disabled={!file} loading={loading} type='primary' htmlType='submit'>
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateCourseAudioDialog
