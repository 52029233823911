import { Button, message, Modal, Slider, Upload } from 'antd'
import { FC, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { UploadOutlined } from '@ant-design/icons'
import * as API_USER from '../../shared/http/user'

interface EditProfileImageProps {
    isOpen: boolean,
    close: () => void
}

const EditProfileImage: FC<EditProfileImageProps> = ({ isOpen, close }) => {

    const [fileList, setSetFileList] = useState<Array<any> | undefined>([])
    const [scale, setScale] = useState<number>(1.2)
    const [loading, setLoading] = useState<boolean>(false)

    const inputRef = useRef<AvatarEditor | null>(null)

    const beforeUpload = (file: any) => {
        const isPNG = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp'
        if (!isPNG) {
            message.error(`${file.name} поддерживаем только jpeg, webp и png форматы`)
        }
        const correctSize = file.size < 500000
        if (!correctSize) {
            message.error(`Размер изображения не должен превышать 5 МБ`)
        }
        return isPNG && correctSize
    }


    const uploadFile = (info: any) => {
        info.onSuccess()
        setSetFileList([info.file])
    }

    const clearAndClose = () => {
        setSetFileList([])
        close()
    }

    const saveImage = () => {
        setLoading(true)
        if (inputRef.current) {
            const canvas = inputRef.current.getImage().toDataURL()
            fetch(canvas)
                .then(res => res.blob())
                .then(blob => {
                    API_USER.updatePhoto(blob as File)
                        .then(() => {
                            window.location.reload()
                        })
                        .catch(() => {
                            message.error(`Не удалось загрузить изображение профиля`)
                        }).finally(() => {
                        setLoading(false)
                        clearAndClose()
                    })
                })
        }

    }

    return (
        <Modal title='Изменение изображения профиля'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={!fileList || fileList?.length <= 0}
                       loading={loading}
                       onClick={saveImage}
                       type='primary'
                   >
                       Сохранить
                   </Button>
               ]}
               open={isOpen}>
            <div className='modal-input-container'>
                {!fileList || fileList?.length <= 0 &&
                <Upload beforeUpload={beforeUpload} fileList={fileList} maxCount={1} customRequest={uploadFile}>
                    <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                </Upload>
                }
                {fileList && fileList?.length > 0 &&
                <>
                    <Slider min={0.8} step={0.1} defaultValue={1.2} max={4} value={scale}
                            onChange={((value) => setScale(value as number))} disabled={false} />
                    <AvatarEditor
                        image={URL.createObjectURL(fileList[0])}
                        ref={inputRef}
                        width={250}
                        borderRadius={250}
                        height={250}
                        border={50}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={scale}
                        rotate={0}
                    />
                </>
                }
            </div>
        </Modal>
    )
}
export default EditProfileImage
