import { useEffect, useState } from 'react'
import '../../../../styles/css/pages/game/fillword.css'
import { ReactP5Wrapper } from '@p5-wrapper/react'
import CrosswordController from './controllers/CrosswordController'
import FillWordWordsListCombineSelector from '../../../blocks/combine-selectors/FillWordWordsListCombineSelector'
import * as API_FILLWORD from '../../../../shared/http/fillWord'
import * as API_STATISTIC from '../../../../shared/http/statistic'
import IconEye from '../../../elements/svg_icons/IconEye'
import IconEyeCross from '../../../elements/svg_icons/IconEyeCross'
import NewCommerceUtil from '../../../utils/NewCommerceUtil'
import Pages from '../../../../const/Pages'
import { setUserRoutine, userSelector } from '../../../../store/global'
import { fetchWorldLists } from '../../../../store/wordLists/extraReducers'
import { fetchCommunities } from '../../../../store/communities/extraReducers'
import { Helmet } from 'react-helmet'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { handleHttpError } from '../../../../common/utils/errors'
import {
    wordsListsIsLoadedStatusSelector,
    wordsListsLoadingStatusSelector
} from '../../../../store/wordLists'
import {
    communitiesDataSelector,
    communitiesLoadedStatusSelector
} from '../../../../store/communities'
import SelectCategory from '../../../elements/selectors/SelectCategory'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleModalDialog from '../../../modalwindows/SimpleModalDialog'
import ConfettiOnTheEnd from '../../../blocks/confetti-on-the-end/ConfettiOnTheEnd'

const FillWord = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const user = useAppSelector(userSelector)
    const setReactUser = (uUser) => dispatch(setUserRoutine(uUser))

    const [viewArray, setViewArray] = useState(null)
    const [selectedWordListId, setSelectedWordListId] = useState(null)
    const [size, setSize] = useState(4)
    const [rusWords, setRusWords] = useState([])
    const [engWords, setEngWords] = useState([])
    const [showAnswers, setShowAnswers] = useState(false)
    const [showEndGameModal, setShowEndGameModal] = useState(false)

    const wordListsIsLoaded = useAppSelector(wordsListsIsLoadedStatusSelector)
    const wordListsIsLoading = useAppSelector(wordsListsLoadingStatusSelector)
    const getUserWordLists = () => dispatch(fetchWorldLists())

    const communities = useAppSelector(communitiesDataSelector)
    const communitiesIsLoaded = useAppSelector(communitiesLoadedStatusSelector)
    const getUserCommunities = () => dispatch(fetchCommunities())

    const sketch = (p) => {
        let cc = new CrosswordController()

        let callSetup = true
        p.setup = () => {
            if (viewArray && callSetup) {
                cc.createCrosswordField(p, viewArray, endGame)
            }
        }

        p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
            if (props.array !== viewArray) {
                p.setup()
                callSetup = false
            }
        }

        p.draw = function() {
            cc.draw(p)
        }
        p.mousePressed = () => {
            cc.updateMousePressed(p, true)
        }

        p.mouseReleased = () => {
            cc.updateMousePressed(p, false)
        }
    }

    const changeWordList = (wordListId) => {
        setSelectedWordListId(wordListId)
        loadCrossword(wordListId, size)
    }

    const changeCrossSize = (nSize) => {
        setSize(nSize)
        loadCrossword(selectedWordListId, nSize)
    }

    const restart = () => {
        loadCrossword(selectedWordListId, size)
    }

    const loadCrossword = (wordListsId, nSize) => {
        if (wordListsId && nSize) {
            API_FILLWORD.getFillWords(wordListsId, nSize)
                .then((resp) => {
                    setViewArray(resp)
                    setRusWords(resp.rusWords)
                    setEngWords(resp.engWords)
                    disableScroll()
                })
                .catch((err) => {
                    handleHttpError(err, navigate)
                })
        }
    }

    const showAnswersClick = () => {
        setShowAnswers(!showAnswers)
    }

    /**
     * Отключаем скроллинг на выбранно области
     */
    const disableScroll = () => {
        const element = document.querySelector('.fill-word_cross-area div')
        if (element) {
            element.onwheel = function(event) {
                event.preventDefault()
            }

            element.onmousewheel = function(event) {
                event.preventDefault()
            }
            element
                .addEventListener('touchmove', (event) => event.preventDefault())
            element
                .addEventListener('touchstart', (event) => event.preventDefault())
            element
                .addEventListener('touchend', (event) => event.preventDefault())
            element
                .addEventListener('touchcancel', (event) => event.preventDefault())
        }
    }

    const endGame = () => {
        setShowEndGameModal(true)
        let countWords = rusWords.length
        API_STATISTIC.saveUserFillWordStatistic({ selectedWordListId, countWords })
    }

    const closeDialog = () => {
        setShowEndGameModal(false)
    }

    const updateUserIfNew = () => {
        const newUser = NewCommerceUtil.showNewCommerceModal(user, Pages.FILL_WORD)
        if (newUser) {
            setReactUser(newUser)
        }
    }

    useEffect(() => {
        beforeMount()
        return () => {
        }
    }, [])

    /**
     * Срабатывает перед монтированием
     */
    const beforeMount = () => {
        updateUserIfNew()
        loadUserWordLists()
        loadUserCommunities()
        preOpenCard()
    }

    /**
     * Открытие карточки по ссылке shared-link
     */
    const preOpenCard = () => {
        // @ts-ignore
        const cardList = location?.state?.cardList
        if (cardList) {
            changeWordList(cardList.id)
        }
    }
    /**
     * Загрузить слова пользователя
     */
    const loadUserWordLists = () => {
        if (!wordListsIsLoaded) {
            getUserWordLists()
        }
    }

    /**
     * Загрузить сообщества пользователя
     */
    const loadUserCommunities = () => {
        if (!communitiesIsLoaded) {
            getUserCommunities()
        }
    }

    return (
        <div id='fill-word'>
            <Helmet title='GE: Филворд' />
            {!viewArray && <SelectCategory message='Выберите список карточек' />}
            <div className='fill-word__rus-words'>
                {rusWords.map((item, i) => (
                    <span key={i}>{item}</span>
                ))}
            </div>
            <div className={viewArray && 'fill-word__content_grid'}>
                {viewArray && (
                    <div className='fill-word_cross-area'>
                        <ReactP5Wrapper sketch={sketch} array={viewArray} />
                    </div>
                )}
                {viewArray && (
                    <div className='fill-word__answers'>
                        {!showAnswers ? (
                            <IconEye onClick={showAnswersClick} />
                        ) : (
                            <>
                                <IconEyeCross onClick={showAnswersClick} />
                                <ul>
                                    {engWords.map((item, i) => (
                                        <li key={i}>{item}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                )}
                <FillWordWordsListCombineSelector
                    selectedWordListId={selectedWordListId}
                    changeCrossSize={changeCrossSize}
                    communities={communities}
                    changeWordList={changeWordList}
                    showWordListLoader={wordListsIsLoading}
                    restart={restart}
                />
            </div>
            <SimpleModalDialog closeDialog={closeDialog} message={'Поздравляю, вы нашли все слова!!!'}
                               open={showEndGameModal} />
            <ConfettiOnTheEnd showConfetti={showEndGameModal} />
        </div>
    )
}

export default FillWord
