import '../../../styles/css/blocks/wordlist_combine_selector.css'
import { CommunityDto, ID } from '../../../shared/types/api'
import { FC } from 'react'
import { Flex, Select, TreeSelect } from 'antd'
import IconRefresh from '../../elements/svg_icons/IconRefresh'
import useTreeSelector from '../../../hooks/useTreeSelector'
import {GELink} from "../../../common/link/GELink";

interface FillWordWordsListCombineSelectorProps {
    changeWordList: (id: number) => void,
    communities: Array<CommunityDto>,
    showWordListLoader: boolean,
    selectedWordListId?: ID,
    changeCrossSize: (size: number) => void,
    restart: () => void
}


const FillWordWordsListCombineSelector: FC<FillWordWordsListCombineSelectorProps> = ({
                                                                                         restart,
                                                                                         changeWordList,
                                                                                         communities,
                                                                                         showWordListLoader,
                                                                                         selectedWordListId,
                                                                                         changeCrossSize
                                                                                     }) => {

    const {
        onChange,
        onLoadData,
        value,
        treeData
    } = useTreeSelector(selectedWordListId, communities, changeWordList)

    return (
        <div id='wordlist-combine-selector'>
            <Flex vertical gap={10}>
                <TreeSelect
                    treeLine={true}
                    loading={showWordListLoader}
                    treeDataSimpleMode
                    style={{ width: '250px' }}
                    value={value}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder='Выберите список карточек'
                    onChange={onChange}
                    loadData={onLoadData}
                    treeData={treeData}
                />

                <Select
                    defaultValue={4}
                    style={{ width: '250px' }}
                    onChange={changeCrossSize}
                    options={[
                        { value: 4, label: '4x4' },
                        { value: 8, label: '8x8' }
                    ]}
                />
            </Flex>
            <button onClick={restart} className='flexbox-center-center'>
                <IconRefresh tooltipText={'Рестарт'} />
            </button>
            <span className='wordlist-combine-selector__help'>
                Нет списков слов, добавьте его в <GELink to='/dictionary'>словаре</GELink> или возьмите
                <GELink to='/donewordslists'> готовый список</GELink>
            </span>
        </div>
    )
}

export default FillWordWordsListCombineSelector
