import {FC} from 'react'

interface IconBookProps {
    className?: string,
    onClick?: () => void
}

export const IconBook: FC<IconBookProps> = ({className = '', onClick}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} viewBox="0 0 24 24" fill="none" stroke="#1890ff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" >
            <path d="M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z" />
            <path d="M19 16h-12a2 2 0 0 0 -2 2" />
            <path d="M9 8h6" />
        </svg>
    )
}
