import {
    executeDeleteMethod,
    executeJsonGetMethod,
    executeJsonPostMethod, executeJsonPutMethod
} from '../api/rest/restApiUtils'
import { WordList } from '../types/api'

/**
 * Создать список слов
 * @method create
 *@param {object} data
 * @return {Response} Fetch Response
 */
export function create(data: WordList) {
    let sendingData = {
        id: 1,
        listName: data.listName ? data.listName : 'Название списка слов',
        type: data.type
    }
    return executeJsonPostMethod('/user/wordlists', sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Удалить список слов
 * @method deleteWordList
 * @param {number} id
 * @return {Response} Fetch Response
 */
export function deleteWordList(id: number) {
    return executeDeleteMethod('/user/wordlists/' + id)
}

/**
 * Обновить список слов
 * @method update
 * @param {object} data
 * @return {Response} Fetch Response
 */
export function update(data: WordList) {
    let id = data.id
    let sendingData = {
        listName: data.listName,
        level: data.level,
        type: data.type,
        sideOneLang: data.sideOneLang,
        sideTwoLang: data.sideTwoLang
    }
    return executeJsonPutMethod('/user/wordlists/' + id, sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Получить списоки слов
 * @method loadUserWordLists
 * @return {Response} Fetch Response
 */
export function getWordLists() {
    return executeJsonGetMethod('/user/wordlists').then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Обновление списка в сообществе
 * @param {number} communityId
 * @param {object} data
 * @method loadUserWordLists
 * @return {Response} Fetch Response
 */
export function updateInCommunity(communityId: number, data: WordList) {
    return executeJsonPutMethod('/user/community/' + communityId + '/wordlist', data)
}

/**
 * Создание списка в стобществе
 * @param {number} communityId
 * @param {object} data
 * @method loadUserWordLists
 * @return {Response} Fetch Response
 */
export function createInCommunity(communityId: number, data: WordList) {
    let sendingData = { listName: data.listName, type: data.type }
    return executeJsonPostMethod('/user/community/' + communityId + '/wordlist', sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Получение списка слов сообщества
 * @method getCommunityWordList
 * @param  {number}  id
 * @return {Response} Fetch Response
 */
export function getCommunityWordList(id: number) {
    return executeJsonGetMethod('/user/community/' + id).then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Проверка доступа к списку слов в сообществе
 * @method checkWordListAccess
 * @param {string} communityKey
 * @param {number} wordListId
 * @return {Response} Fetch Response
 */
export function checkCommunityWordListAccess(communityKey: string, wordListId: number) {
    return executeJsonGetMethod(`/user/wordlists/access/${communityKey}/${wordListId}`)
}

/**
 * Проверка доступа к списку слов
 * @method checkWordListAccess
 * @param {number} wordListId
 * @return {Response} Fetch Response
 */
export function checkWordListAccess(wordListId: number) {
    return executeJsonGetMethod(`/user/wordlists/access/${wordListId}`)
}

export function getCountWordsListsUser() {
    return executeJsonGetMethod(`/user/wordslists/count`)
}

export function switchSidesWordList(wordListId: number) {
    return executeJsonPostMethod(`/user/wordlists/${wordListId}/switch/sides`)
}
