let ValidateUser = {
    regExpPassword: '^.{6,20}$',
    regExpEmail:
        "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])$",
    regExpLogin: '^[a-zA-Z][a-zA-Z0-9-_\\.]{2,20}$',

    errorRequiredField: 'Поле обязательно для заполнения',
    errorValidPassword:
        'В пароле должно быть от 6 до 20 символов',

    errorLogin:
        'Логин должен иметь длину от 3 до 20 символов и начинаться с латинской буквы. Также допустимы цифры, символ _ и -',
    errorEmail: 'Введите корректный email',
    errorLoginCoincidence: 'Этот логин уже занят',

    checkValidPassword: function (password) {
        let regExp = new RegExp(this.regExpPassword)
        return regExp.test(password)
    },

    getPasswordErrors: function (password, passwordConfirm) {
        let errors = []
        if (!this.checkValidPassword(password)) {
            errors.push(this.errorValidPassword)
        }
        return errors
    },

}

export default ValidateUser
