import { useState, useEffect } from 'react'
import { Input, Form, Spin, Checkbox } from 'antd'
import IconMultiply from '../elements/svg_icons/IconMultiply'
import ValidateUser from '../utils/ValidateUser'
import '../../styles/css/modalwindows/modal_login.css'
import TelegramLoginButton from '../elements/buttons/TelegramLoginButton'
import * as API_USER from '../../shared/http/user'
import { createFormValidationDebounce } from '../utils/createFormValidationDebounce'
import { GEButton } from '../../common/buttons'
import GoogleAuthButton from '../elements/buttons/GoogleAuthButton'
import AppAuthButton from '../elements/buttons/AppAuthButton'
import { NavigationPages } from '../../common/navigation/navigation-pages'
import { GELink } from '../../common/link/GELink'

const Modal = (props) => {
    const {
        className = '',
        type = 'login',
        isLoading,
        onLogin = () => null,
        onRegister = () => null,
        onForgot = () => null,
        onClose = () => null
    } = props

    const [formType, setFormType] = useState({
        formTitle: 'Вход в аккаунт',
        type: 'login'
    })

    const debValidateLogin = createFormValidationDebounce((value) => API_USER.getLoginCoincidence(value), 1000)

    const onFinish = (e) => {
        if (formType.type === 'register') {
            onRegister({
                referralUserId: null,
                login: e.registerLogin,
                email: e.registerEmail,
                password: e.registerPassword,
                notificationFlag: e.notificationFlag
            })
        } else {
            onLogin({
                login: e.signInLogin,
                password: e.signInPassword
            })
        }
    }

    const handleToRegister = () =>
        setFormType({
            formTitle: 'Регистрация',
            type: 'register'
        })

    const handleToLogin = () =>
        setFormType({
            formTitle: 'Вход в аккаунт',
            type: 'login'
        })

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    }

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 }
    }

    const renderFormLogin = () => (
        <>
            <Form.Item
                className={'modal-login__form-item'}
                name={'signInLogin'}
                label={'Логин или email'}
                rules={[{ required: true, message: 'Необходимо ввести логин или email' }]}
            >
                <Input placeholder={'Введите логин или email'} />
            </Form.Item>
            <Form.Item
                className={'modal-login__form-item'}
                name={'signInPassword'}
                label={'Пароль'}
                rules={[{ required: true, message: 'Необходимо ввести пароль' }]}
            >
                <Input.Password placeholder={'Введите пароль'} />
            </Form.Item>
        </>
    )

    const renderFormRegister = () => (
        <>
            <Form.Item
                className={'modal-login__form-item'}
                name={'registerEmail'}
                label={'Электронная почта'}
                rules={[
                    { required: true, message: 'Необходимо ввести email' },
                    { pattern: ValidateUser.regExpEmail, message: ValidateUser.errorEmail }
                ]}
            >
                <Input placeholder={'example@mail.ru'} />
            </Form.Item>
            <Form.Item
                className={'modal-login__form-item'}
                name={'registerLogin'}
                label={'Логин'}
                validateFirst
                rules={[
                    { required: true, message: 'Необходимо ввести логин' },
                    { pattern: ValidateUser.regExpLogin, message: ValidateUser.errorLogin },
                    {
                        validator(_, value) {
                            return new Promise((resolve, reject) => {
                                debValidateLogin(value).then((resp) => {
                                    if (resp) {
                                        return reject(new Error(ValidateUser.errorLoginCoincidence))
                                    }
                                    return resolve()
                                })
                            })
                        }
                    }
                ]}
            >
                <Input placeholder={'Придумайте логин'} />
            </Form.Item>
            <Form.Item
                className={'modal-login__form-item'}
                name={'registerPassword'}
                label={'Пароль'}
                rules={[
                    { required: true, message: 'Пароли должны совпадать' },
                    { pattern: ValidateUser.regExpPassword, message: ValidateUser.errorValidPassword }
                ]}
            >
                <Input.Password placeholder={'Введите пароль'} />
            </Form.Item>
            <Form.Item
                className={'modal-login__form-item modal-login__notification'}
                name='notificationFlag'
                valuePropName='checked'
            >
                <Checkbox>
                    {' '}
                    <span className='modal-login__notification__label'> Включить уведомления</span>
                </Checkbox>
            </Form.Item>
            <div className={'modal-login__policy'}>
                Нажимая кнопку регистрации, Вы соглашаетесь с нашей
                <br />
                <GELink to='/personal-policy'> политикой конфиденциальности</GELink>,
                <br />
                а также безоговорочно и полностью принимаете условия
                <GELink to='/aferta'> Оферты</GELink>
            </div>
        </>
    )

    useEffect(() => {
        if (type === 'login') {
            setFormType({
                formTitle: 'Вход в аккаунт',
                type: 'login'
            })
        } else {
            setFormType({
                formTitle: 'Регистрация',
                type: 'register'
            })
        }
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    return (
        <div className={'modal-login ' + className}>
            <div className={'modal-login__body'}>
                <Spin spinning={isLoading}>
                    <h2 className={'modal-login__title'}>{formType.formTitle}</h2>
                    <Form
                        initialValues={{ notificationFlag: true }}
                        {...layout}
                        className={'modal-login__form'}
                        name={'login'}
                        onFinish={onFinish}
                    >
                        {formType.type === 'login' ? renderFormLogin() : renderFormRegister()}
                        <GEButton className='login-registration-btn width180px' {...tailLayout} type={'primary'}
                                  htmlType={'submit'}>
                            {formType.type === 'login' ? 'Войти' : 'Регистрация'}
                        </GEButton>
                    </Form>
                    {window.location.hostname === 'geekenglish.ru' && (
                        <TelegramLoginButton botName={'GeekenglishBot'} />
                    )}
                    <div className='login-registration-btn-area'>
                        <GoogleAuthButton />
                        <AppAuthButton />
                    </div>
                    {formType.type === 'register' ? (
                        <GELink className={'modal-login__link'} onClick={handleToLogin}>
                            Уже есть аккаунт? Войти
                        </GELink>
                    ) : (
                        <>
                            <GELink className={'modal-login__link'} onClick={handleToRegister}>
                                У нас впервые? Зарегистрируйтесь!
                            </GELink>
                            <GELink className={'modal-login__link'} to={NavigationPages.RESET_PASSWORD}>
                                Забыли пароль?
                            </GELink>
                        </>
                    )}

                    <button className={'modal-login__btn-close'} onClick={onClose}>
                        <IconMultiply className={'close-icon'} />
                    </button>
                </Spin>
            </div>
        </div>
    )
}

export default Modal
