import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
    communitiesDataSelector, communitiesLoadedStatusSelector,
    setCommunitiesRoutine
} from '../../../../store/communities'
import { fetchCommunities } from '../../../../store/communities/extraReducers'
import { premiumStatusSelector } from '../../../../store/global'
import { wordListsDataSelector } from '../../../../store/wordLists'
import '../../../../styles/scss/dictionary/dictionary.scss'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectCardList } from '../../../helpers/card-list-helper'
import { wordsDataSelector } from '../../../../store/words'
import { Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import DictionaryMyLists from './DictionaryMyLists'
import DictionaryCommunityLists from './DictionaryCommunityLists'

const Dictionary = () => {
    const wordLists = useAppSelector(wordListsDataSelector)
    const cards = useAppSelector(wordsDataSelector)
    const communities = useAppSelector(communitiesDataSelector)
    const communitiesIsLoaded = useAppSelector(communitiesLoadedStatusSelector)
    const isPremium = useAppSelector(premiumStatusSelector)
    const dispatch = useAppDispatch()

    useEffect(() => {
        return cancelAllWorListChanges()
    }, [])

    const disableActiveCommunity = () => {
        const newCommunities = communities.map((c) => ({
            ...c,
            active: false
        }))
        dispatch(setCommunitiesRoutine(newCommunities))
    }

    const getCommunities = () => {
        if (!communitiesIsLoaded) {
            dispatch(fetchCommunities())
        }
    }

    useEffect(() => {
        getCommunities()
    }, [])


    const cancelAllWorListChanges = () => {
        selectCardList(dispatch, 'null-list', wordLists, cards)
        disableActiveCommunity()
    }

    return (
        <div className='dictionary' id='dictionary'>
            <Helmet title='GE: Словарь' />
            <Tabs type='card' defaultActiveKey='0' destroyInactiveTabPane={true}>
                <TabPane tab='Мои списки' key='0'>
                    <DictionaryMyLists />
                </TabPane>
                {communities && communities.filter(item => item.name).map((item, i) => (
                    <TabPane tab={item.name} key={item.id}>
                        <DictionaryCommunityLists community={item} />
                    </TabPane>
                ))}
            </Tabs>
        </div>
    )
}
export default Dictionary
