import { FC } from 'react'

interface DownloadIconProps {
    className?: string,
    onClick?: () => void
}

export const DownloadIcon: FC<DownloadIconProps> = ({ className = '', onClick }) => {

    return (
        <svg
            onClick={onClick}
            className={className}
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            viewBox='0 0 24 24'
        >
            <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5 5-5M12 4v12'></path>
        </svg>
    )
}
