import React, { FC } from 'react'
import { Button, Flex, Typography } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { ModuleAudioExerciseDto } from '../../../../../shared/types/api'
import * as API_COURSES from '../../../../../shared/http/courses'


const { Text } = Typography

interface AudioPlayerProps {
    ex: ModuleAudioExerciseDto,
}

export const CourseAudioPlayer: FC<AudioPlayerProps> = ({ ex }) => {


    const playAudio = () => {
        API_COURSES.playAudioExercise(Number(ex.id))
        //audio.play()
    }

    return (
        <>
            <Text>{ex.data.title}</Text>
            <Flex style={{ marginTop: 8 }} align={'center'} gap={16}>
                <Button
                    type='default'
                    icon={<PlayCircleOutlined />}
                    onClick={playAudio}
                >
                    Прослушать аудио
                </Button>
            </Flex>
        </>
    )
}

