export interface dataGridItem {
    href: string
    imageSrc: string
    title: string
    cardBody: string
    hoverTitle: string
    isPremium: boolean
    enable: boolean
}

export const data: dataGridItem[] = [
    {
        href: 'profile',
        imageSrc: '',
        title: 'Профиль',
        cardBody: 'Профиль',
        hoverTitle: 'Ваш профиль',
        isPremium: false,
        enable: true
    },
    {
        href: 'dictionary',
        imageSrc: '../../images/mainpage/cards/dictionary.png',
        title: 'Словарь',
        cardBody: 'Добавляй свои списки карточек',
        hoverTitle: 'You can add new category with words',
        isPremium: false,
        enable: true
    },
    {
        href: 'cards',
        imageSrc: '../../images/mainpage/cards/cards.png',
        title: 'Карточки',
        cardBody: 'Запоминай слова по карточкам',
        hoverTitle: 'Memorize the words from the cards',
        isPremium: false,
        enable: true
    },
    {
        href: 'test',
        imageSrc: '../../images/mainpage/cards/test.png',
        title: 'Тесты',
        cardBody: 'Запоминай слова по тестам',
        hoverTitle: 'Complete the test, translate from English in to Russian',
        isPremium: false,
        enable: true
    },
    {
        href: 'guess-word',
        imageSrc: '../../images/mainpage/cards/fill-gaps.png',
        title: 'Заполни пропуски',
        cardBody: 'Заполняй пропущенные символы в словах',
        hoverTitle: 'Fill gaps in words',
        isPremium: false,
        enable: true
    },
    {
        href: 'fillwords',
        imageSrc: '../../images/mainpage/cards/scrabble.png',
        title: 'Филворды',
        cardBody: 'Играй в игру и учи Английский язык',
        hoverTitle: 'Play in game',
        isPremium: false,
        enable: true
    },
    {
        href: 'donewordslists',
        imageSrc: '../../images/mainpage/cards/wordlist.png',
        title: 'Готовые списки карточек',
        cardBody: 'Добавляй готовые списки карточек',
        hoverTitle: 'Lists of vocabulary words',
        isPremium: false,
        enable: true
    },
    {
        href: 'communities',
        imageSrc: '../../images/mainpage/cards/communities.png',
        title: 'Сообщества',
        cardBody: 'Учите вместе',
        hoverTitle: 'Learn together',
        isPremium: false,
        enable: true
    },
    {
        href: 'courses-list',
        imageSrc: '../../images/mainpage/cards/courses.png',
        title: 'Курсы',
        cardBody: 'Учите с преподавателями',
        hoverTitle: 'Learn with teachers',
        isPremium: false,
        enable: true
    },
    {
        href: 'phrases',
        imageSrc: '../../images/mainpage/cards/phrases.png',
        title: 'Phrases',
        cardBody: 'Learn english phrases.',
        hoverTitle: 'htitle',
        isPremium: false,
        enable: false
    },
    {
        href: 'statistic',
        imageSrc: '../../images/mainpage/cards/statistic.png',
        title: 'Статистика',
        cardBody: 'Следи за статистикой',
        hoverTitle: 'It shows statistic',
        isPremium: false,
        enable: true
    },
    {
        href: 'rules',
        imageSrc: '../../images/mainpage/cards/grammar.png',
        title: 'Теория',
        cardBody: 'Учи правила Английского языка',
        hoverTitle: 'Learn the English grammar rules',
        isPremium: false,
        enable: true
    },
    {
        href: 'letter',
        imageSrc: '../../images/mainpage/cards/letter_insertion.png',
        title: 'Letter insertion',
        cardBody: 'Learn english with letter insertion.',
        hoverTitle: 'htitle',
        isPremium: false,
        enable: false
    },
    {
        href: 'library',
        imageSrc: '../../images/mainpage/cards/library.jpg',
        title: 'Library',
        cardBody: 'You can find useful links',
        hoverTitle: 'Изучай другие медиа материалы',
        isPremium: false,
        enable: false
    },
    /* {
         href: 'dialogs',
         imageSrc: '../../images/mainpage/cards/dialogs.png',
         title: 'Диалоги',
         cardBody: 'Общайся с друзьями',
         hoverTitle: 'Sent messages to friends',
         isPremium: false,
         enable: !__PRODUCTION__
     },*/
    {
        href: 'faq',
        imageSrc: '../../images/mainpage/cards/faq.png',
        title: 'FAQ',
        cardBody: 'Справка',
        hoverTitle: 'Do you have any questions?',
        isPremium: false,
        enable: false
    }
]
