// src/CreateCourse.js
import React, { FC, useState } from 'react'
import { Form, Input, Button, Select, InputNumber, Flex, message, TimePicker } from 'antd'
import Constants from '../../../../../const/Constants'
import Dragger from 'antd/es/upload/Dragger'
import { CourseDto } from '../../../../../shared/types/api'
import { useParams } from 'react-router-dom'
import * as API_COURSES from '../../../../../shared/http/courses'
import { IconPhoto } from '../../../../elements/svg_icons/IconPhoto'
import dayjs from 'dayjs'
import Link from 'antd/es/typography/Link'


const { TextArea } = Input

interface MainPageCreateCourseProps {
    course: CourseDto,
    refresh: () => void
}

const MainPageCreateCourse: FC<MainPageCreateCourseProps> = ({ course, refresh }) => {

    const { id } = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [deleteHeaderLoading, setDeleteHeaderLoading] = useState<boolean>(false)

    // Функция обработки отправки формы
    const onFinish = (values: any) => {
        if (values.duration) {
            const moment1 = dayjs(values.duration)
            const s = moment1.format('HH:mm')
            values.duration = s
        }
        setLoading(true)
        API_COURSES.editCourse(Number(id), values).then(c => {
            message.success('Изменения сохранены')
            refresh()
        }).finally(() => {
            setLoading(false)
        })
    }

    const levels = Constants.CEFR_LEVELS.map(item => {
        return { value: item, label: item }
    })

    const beforeUpload = (file: any) => {
        const isValid = file.type === 'image/png' || file.type === 'image/jpeg'
        if (!isValid) {
            message.error(`${file.name} поддерживаем только jpeg, png форматы`)
        }
        return isValid
    }

    const uploadImage = (info: any) => {
        API_COURSES.uploadImage(Number(id), info.file).then(() => {
            refresh()
            info.onSuccess()
        })
    }

    const deleteHeaderImage = () => {
        setDeleteHeaderLoading(true)
        API_COURSES.deleteHeaderImage(course.id).then(e => {
            refresh()
        }).finally(() => {
            setDeleteHeaderLoading(false)
        })
    }

    return (
        <div>

            <Flex justify={'space-between'}>
                <h1>Редактирование курса</h1>
                <h2>{course?.status}</h2>
            </Flex>
            {course.headerImage && <Flex justify={'space-around'} align={'center'}>
                <img height={524 * 0.3} width={1200 * 0.3}
                     src={`${Constants.queryConst}/courses/${course.headerImage}/image/header`}
                     alt='Изображение курса' />
                <Button loading={deleteHeaderLoading} type='primary' danger={true} htmlType='submit'
                        onClick={deleteHeaderImage}> Удалить
                    изображение </Button>
            </Flex>}

            <Form
                layout='vertical'
                onFinish={onFinish}
                initialValues={
                    {
                        title: course.title,
                        duration: course.duration ? dayjs(course.duration, 'HH:mm:ss') : undefined,
                        description: course.description,
                        countLessons: course.countLessons,
                        level: course.level,
                        price: course.price,
                        courseAuthorName: course.courseAuthor?.name,
                        courseAuthorDescription: course.courseAuthor?.description,
                        sale: course.price === course.oldPrice ? 0 : 100 - (course.price / course.oldPrice) * 100
                    }
                }
            >

                {!course.headerImage && <Dragger beforeUpload={beforeUpload} customRequest={uploadImage} maxCount={1}>
                    <p className='ant-upload-drag-icon'>
                        <IconPhoto />
                    </p>
                    <p className='ant-upload-text'>Изображение курса</p>
                    <p className='ant-upload-hint'>
                        1200px × 524px в формате jpeg или png
                    </p>
                </Dragger>}
                <span>Чтобы отредактировать ваше изображение можете воспользоваться сервисом <Link
                    href={'https://www.iloveimg.com/ru/resize-image'}>IloveImg</Link></span>
                <Form.Item
                    label='Название курса'
                    name='title'
                    rules={[{ required: true, message: 'Пожалуйста, введите название курса' }]}
                >
                    <Input size={'large'} placeholder='Например, English for Beginners' />
                </Form.Item>


                <Form.Item
                    label='Стоимость курса ₽'
                    name='price'
                    rules={[{ required: true, message: 'Пожалуйста, введите стоимость курса' }]}
                >
                    <InputNumber size='large' addonAfter='₽' min={0} max={500000} />
                </Form.Item>

                <Form.Item
                    label='Скидка на курс %'
                    name='sale'
                >
                    <InputNumber size='large' addonAfter='%' min={0} max={100} />
                </Form.Item>

                <Form.Item
                    label='Описание курса'
                    name='description'
                    rules={[{ required: true, message: 'Пожалуйста, введите описание курса' }]}
                >
                    <TextArea size='large' rows={4} placeholder='Введите краткое описание курса' />
                </Form.Item>

                <h2>Детали курса</h2>

                <Form.Item
                    label='Число уроков'
                    name='countLessons'
                >
                    <InputNumber size='large' min={0} max={100} style={{ width: 200 }} />
                </Form.Item>

                <Form.Item
                    label='Уровень'
                    name='level'
                >
                    <Select
                        style={{ width: 200 }}
                        size='large'
                        options={levels}
                        defaultValue={'Выбрать уровень'}
                    />
                </Form.Item>

                <Form.Item
                    label='Длительность'
                    name='duration'
                >
                    <TimePicker format={'HH:mm'} size='large' placeholder='Выберите длительность' />
                </Form.Item>

                <h2>Информация об авторе курса</h2>

                <Form.Item
                    label='Имя'
                    name='courseAuthorName'
                    rules={[{ required: true, message: 'Пожалуйста, заполните информацию об авторе' }]}
                >
                    <Input size='large' min={0} max={100} style={{ width: 200 }} />
                </Form.Item>

                <Form.Item
                    label='Об авторе'
                    name='courseAuthorDescription'
                    rules={[{ required: true, message: 'Пожалуйста, заполните информацию об авторе' }]}
                >
                    <TextArea size='large' rows={4} placeholder='Заполните информацию об авторе' />
                </Form.Item>


                <Form.Item>
                    <Button
                        loading={loading}
                        htmlType='submit'
                    >
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

export default MainPageCreateCourse
