import { FC } from 'react'
import { Tooltip } from 'antd'

interface IconFileTextProps {
    className?: string,
    width?: number,
    height?: number,
    strokeWidth?: number,
    stroke?: string,
    tooltipText?: string
    onClick?: () => void
}

export const IconFileText: FC<IconFileTextProps> = ({
                                                        width = 44,
                                                        height = 44,
                                                        strokeWidth = 2,
                                                        stroke = '#1890ff',
                                                        className = '',
                                                        onClick,
                                                        tooltipText
                                                    }) => {

    if (tooltipText) {
        return (
            <Tooltip title={tooltipText}>
                <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'
                     stroke={stroke} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round'>
                    <path d='M14 3v4a1 1 0 0 0 1 1h4'></path>
                    <path d='M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2'></path>
                    <path d='M11 14h1v4h1M12 11h.01'></path>
                </svg>
            </Tooltip>
        )
    }

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'
             stroke={stroke} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round'>
            <path d='M14 3v4a1 1 0 0 0 1 1h4'></path>
            <path d='M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2'></path>
            <path d='M11 14h1v4h1M12 11h.01'></path>
        </svg>
    )
}
