/**
 * Helper methods for determining environment
 * @module environs
 * @memberof utils
 */

let VERSION = 1.0

/**
 * Expose some env helper tools
 * @type {Object}
 */

/**
 * Detect release environment
 * @type {boolean}
 */
export const __RELEASE__ = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'

/**
 * Detect production environment
 * @type {boolean}
 */
export const __PRODUCTION__ = process.env.NODE_ENV === 'production'

/**
 * Detect testing environment
 * @type {boolean}
 */
export const __TESTING__ = process.env.NODE_ENV === 'testing' || process.env.NODE_ENV === 'test'

/**
 * Detect dev environment
 * @type {boolean}
 */
export const __DEV__ = process.env.NODE_ENV === 'development'

/**
 * Returns version
 * @type number
 */
export const __VERSION__ = VERSION

/**
 * Get an environment variable
 * @method getEnv
 * @param  {string} env var to get
 * @return {any}    returns variable
 */
export const __GET__ = (env) => process.env[env]
