import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts'
import MotivationalQuotesUtil from '../utils/MotivationalQuotesUtil'
import '../../styles/css/dictionary/modalwindow/modalw.css'
import IconClose from '../elements/svg_icons/IconClose'
import { FC } from 'react'
import { Button, Modal } from 'antd'

interface CompletedTestRuleDialogProps {
    amountWordsInTest: number;
    close: () => void;
    rightAnswers: number;
    open: boolean;
}

const CompletedTestRuleDialog: FC<CompletedTestRuleDialogProps> = ({
                                                                       amountWordsInTest,
                                                                       close,
                                                                       rightAnswers,
                                                                       open
                                                                   }) => {
    const COLORS = ['#1890FF', '#e33a4e']
    const formatGraphData = (right: number, amount: number) => {
        return [
            {
                name: 'Верных ответов',
                value: right
            },
            {
                name: 'Ошибок',
                value: amount - right
            }
        ]
    }
    return (
        <Modal onCancel={close}
               footer={[
                   <Button danger onClick={close}>
                       Закрыть
                   </Button>
               ]}
               open={open}>
            <div className='modal_window_bg_layer' />
            <div id='main-dialog' className='modal_window_style modal_window_style_test'>
                <div className='modal_window_style--close' onClick={close}>
                    <IconClose />
                </div>
                <span>{MotivationalQuotesUtil.generateRandomMotivationQuote()}</span>
                {/* @ts-ignore*/}
                <PieChart style={{ fontSize: '1rem', margin: '0 auto' }} width={300} height={300}>
                    <Tooltip />
                    <Legend />
                    <Pie
                        data={formatGraphData(rightAnswers, amountWordsInTest)}
                        dataKey='value'
                        nameKey='name'
                        cx='50%'
                        cy='50%'
                        outerRadius={120}
                        fill='#92e4a4'
                    >
                        {formatGraphData(rightAnswers, amountWordsInTest).map((entry, index) => (
                            <Cell key={'cell' + index} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </div>
        </Modal>
    )
}

export default CompletedTestRuleDialog

