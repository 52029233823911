import PropTypes from 'prop-types'
import '../../../../../styles/scss/wordlist-item/wordlistitem.scss'
import PrivilegesUtil from '../../../../utils/PrivilegesUtil'
import Privileges from '../../../../../const/Privileges'
import WordListUtils from '../../../../utils/WordListUtils'
import Constants from '../../../../../const/Constants'
import Speaker from '../../../../elements/svg_icons/Speaker'
import { Dropdown, Flex, Modal, Progress, Space, Tooltip } from 'antd'
import { useState } from 'react'
import { deleteWordList, fetchWorldLists } from '../../../../../store/wordLists/extraReducers'
import { wordListByIdSelector } from '../../../../../store/wordLists'
import { premiumStatusSelector, userSelector } from '../../../../../store/global'
import Loading from '../../../../elements/loading/Loading'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import IconLetterCase from '../../../../elements/svg_icons/IconLetterCase'
import * as API_DONE_WORDLIST from '../../../../../shared/http/done-word-list'
import EditCardListDialog from '../../../../modalwindows/EditCardListDialog'
import { GearIcon } from '../../../../elements/svg_icons/GearIcon'
import { DropdownCardMenu } from '../../profilepage/newProfile/DropdownCardMenu'
import ConfirmModalDialog from '../../../../modalwindows/ConfirmModalDialog'
import CloneToCommunityModalDialog from '../../../../modalwindows/CloneToCommunityModalDialog'
import { communitiesDataSelector } from '../../../../../store/communities'
import { CardsDictionaryDialog } from '../../../../modalwindows/CardsDictionaryDialog'
import { GEButton } from '../../../../../common/buttons'
import LearnCardDialog from '../../../../modalwindows/LearnCardDialog'
import * as API_SUBMIT_WORDLIST from '../../../../../shared/http/submitWordList'
import Paragraph from 'antd/es/skeleton/Paragraph'

const WordListItem = (props) => {
    const user = useAppSelector(userSelector)
    const dispatch = useAppDispatch()
    const communities = useAppSelector(communitiesDataSelector)
    const isPremium = useAppSelector(premiumStatusSelector)
    const { id, listName, type, level, repeatedDate, imageId } = props.wordList
    const canEditWordList = WordListUtils.canEditWordList(props.wordList, user, props.canEditCommunity)
    const [showEditListDialog, setShowEditListDialog] = useState(false)
    const [showCloneToCommunityModal, setShowCloneToCommunityModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showCardsDictionaryDialog, setShowCardsDictionaryDialog] = useState(false)
    const [showLearnCardsDialog, setShowLearnCardsDialog] = useState(false)

    /**
     * Сделать список слов готовым
     * @param id
     */
    const makeListDone = (id) =>
        API_DONE_WORDLIST.transformUserListToDoneList(id).then(() => {
            dispatch(fetchWorldLists())
        })

    /**
     * Сделать все буквы в листе заглаными
     * @param id
     */
    const makeLettersUppercase = (id) =>
        API_DONE_WORDLIST.makeLettersUppercase(id).then(() => {
            dispatch(fetchWorldLists())
        })

    const wordList = useAppSelector(wordListByIdSelector(id))

    const getWordListItemClassName = (wlType, wlChecked) => {
        const defaultClassName = 'dictionary__word-list-item'
        let isDone = WordListUtils.isDoneType(wlType) ? 'yellowBackGround' : 'blueBackGround'
        let isSelected = wlChecked ? 'dictionary__word-list-item-selected' : ''
        return defaultClassName + ' ' + isDone + ' ' + isSelected
    }
    /**
     * Модальное окно для редактирования карточки
     */
    const closeEditCardListDialog = () => setShowEditListDialog(false)
    const openEditCardListDialog = () => {
        setShowEditListDialog(true)
    }

    /**
     * Модальное окно для клонирования карточки
     */
    const openCloneToCommunityModal = () => {
        setShowCloneToCommunityModal(true)
    }

    /**
     * Модальное окно для удаления карточки
     */
    const openConfirmModal = () => {
        setShowConfirmModal(true)
    }
    const closeConfirmModal = () => setShowConfirmModal(false)
    const deleteCardList = () => {
        dispatch(deleteWordList(wordList.id))
    }

    const adminCommunities = communities ? communities.filter((c) => c.canEdit) : []

    const isString = (variable) => {
        return typeof variable === 'string'
    }


    const getProgressBar = (rD) => {
        const width = 35
        const type = 'circle'
        const daysToRepeat = WordListUtils.calculateDaysToRepeat(repeatedDate)
        if (daysToRepeat === 0) {
            return (
                <Progress
                    width={width}
                    type={type}
                    strokeColor={'red'}
                    status={'normal'}
                    percent={100}
                    format={(percent) => '0'}
                />
            )
        } else if (daysToRepeat <= 100) {
            return (
                <Progress
                    width={width}
                    type={type}
                    strokeColor={'green'}
                    status={'normal'}
                    percent={100 - daysToRepeat}
                    format={(percent) => `${100 - percent}`}
                />
            )
        }
        return (
            <Progress
                width={width}
                type={type}
                strokeColor={'green'}
                status={'normal'}
                percent={0}
                format={(percent) => '100+'}
            />
        )
    }

    const submitList = (listId) => {
        API_SUBMIT_WORDLIST.submit(listId)
            // @ts-ignore
            .then(() => {
                Modal.success({
                    centered: true,
                    destroyOnClose: true,
                    okText: 'Понятно',
                    // @ts-ignore
                    getContainer: () => document.querySelector('#dictionary'),
                    content: (
                        <span>
                            Спасибо за список, постараемся рассмотреть его в ближайшее время!
                        </span>
                    )
                })
            })
    }

    const handleMenuClick = (e) => {
        switch (e.key) {
            case DropdownCardMenu.RENAME:
                openEditCardListDialog()
                break

            case DropdownCardMenu.CLONE_INTO_COMMUNITY:
                openCloneToCommunityModal()
                break

            case DropdownCardMenu.DELETE_CARD:
                openConfirmModal()
                break

            case DropdownCardMenu.SHARE_LIST:
                props.shareWordList(id)
                break

            case DropdownCardMenu.MAKE_READY:
                submitList(id)
                break
        }
    }

    const createItems = () => {
        const result = []
        result.push({
            label: 'Переименовать',
            key: DropdownCardMenu.RENAME
        })
        if (isPremium && adminCommunities.length > 0) {
            result.push({
                label: 'Копировать в сообщество',
                key: DropdownCardMenu.CLONE_INTO_COMMUNITY
            })
        }
        if (WordListUtils.isCommunityType(type)) {
            result.push({
                label: 'Поделиться',
                key: DropdownCardMenu.SHARE_LIST
            })
        }
        result.push({
            label: 'Удалить',
            key: DropdownCardMenu.DELETE_CARD
        })
        result.push({
            label: 'Предложить сделать готовым',
            key: DropdownCardMenu.MAKE_READY
        })
        return result
    }

    const items = createItems()

    const menuItems = {
        items,
        onClick: handleMenuClick
    }

    const closeModal = () => {
        setShowCardsDictionaryDialog(false)
    }

    const openLearnCardsDialog = () => {
        setShowLearnCardsDialog(true)
    }

    return (
        <>
            {wordList.isLoading ? (
                <Loading />
            ) : (
                <li className={getWordListItemClassName(type)}>
                    {/*level*/}
                    <span className='word-list-item__list-level'>
                        <Tooltip
                            title={
                                WordListUtils.calculateDaysToRepeat(repeatedDate) === 0
                                    ? 'Пора повторить слова'
                                    : 'Дней до повторения'
                            }
                        >
                            {getProgressBar(repeatedDate)}
                        </Tooltip>
                        <span>{level}</span>
                        {(canEditWordList || WordListUtils.isNewWordList(id)) && (
                            <Dropdown menu={menuItems} trigger={['click']}>
                                <Space>
                                    <GearIcon />
                                </Space>
                            </Dropdown>
                        )}
                    </span>

                    {/*image*/}
                    <img
                        className='word-list-item__list-image'
                        src={`${Constants.queryConst}/file/wordlist/image?id=` + imageId}
                        loading='lazy'
                        alt='list of wordlist'
                        style={{ flexShrink: 0 }}
                    />
                    {/*listName*/}
                    <span className={'word-list-item__list-name'}>{listName}</span>
                    <Flex vertical gap={'8px'} style={{ marginTop: 'auto' }} className="word-list-item__buttons">
                        <GEButton onClick={() => setShowCardsDictionaryDialog(true)}>
                            Показать список
                        </GEButton>
                        <GEButton type={'primary'} onClick={openLearnCardsDialog}>Учить слова</GEButton>
                    </Flex>

                    <div className='word-list-item__admin-btns'>
                        {(WordListUtils.isUserType(type) || WordListUtils.isCommunityType(type)) &&
                        PrivilegesUtil.hasPrivilege(user, Privileges.EDIT_DONE_WORD_LIST) && (
                            <>
                                <Speaker onClick={() => makeListDone(id)} class='fill-white' />
                                <IconLetterCase onClick={() => makeLettersUppercase(id)} class='fill-white' />
                            </>
                        )}
                        {WordListUtils.isDoneType(type) &&
                        PrivilegesUtil.hasPrivilege(user, Privileges.EDIT_DONE_WORD_LIST) &&
                        (
                            <>
                                <span className={'word-list-item__list-level'}>{level}</span>
                                <IconLetterCase onClick={() => makeLettersUppercase(id)} class='fill-white' />
                            </>
                        )}
                    </div>
                    <EditCardListDialog
                        decline={closeEditCardListDialog}
                        open={showEditListDialog}
                        cardList={props.wordList} />
                    <CloneToCommunityModalDialog
                        cardList={wordList}
                        communities={adminCommunities}
                        open={showCloneToCommunityModal}
                        close={() => setShowCloneToCommunityModal(false)} />
                    <ConfirmModalDialog
                        decline={closeConfirmModal}
                        message={'Вы уверены, что хотите удалить список карточек?'}
                        open={showConfirmModal}
                        confirm={deleteCardList} />

                    {showCardsDictionaryDialog &&
                    <CardsDictionaryDialog wordList={wordList} isOpen={showCardsDictionaryDialog}
                                           canEditWordList={canEditWordList}
                                           close={closeModal} />}
                    <LearnCardDialog wordList={wordList} open={showLearnCardsDialog}
                                     close={() => setShowLearnCardsDialog(false)} />
                </li>
            )}
        </>
    )
}

export default WordListItem

WordListItem.propTypes = {
    cancelEdit: PropTypes.func,
    deleteWordList: PropTypes.func,
    editMode: PropTypes.func,
    onChangeListName: PropTypes.func,
    onChangeListLevel: PropTypes.func,
    selectWordList: PropTypes.func,
    shareWordList: PropTypes.func.isRequired,
    wordList: PropTypes.object
}
