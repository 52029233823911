import { useEffect } from 'react'
import Constants from '../../../../const/Constants'
import IconPlayStore from '../../../elements/svg_icons/IconPlayStore'
import IconAppStore from '../../../elements/svg_icons/IconAppStore'
import { Flex } from 'antd'

export const DownLoadApp = () => {

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {

        }

        if (/android/i.test(userAgent)) {
            window.open(Constants.GOOGLE_PLAY_LINK)
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.open(Constants.APPLE_STORE_LINK)

        }
    }, [])

    return (
        <Flex style={{ margin: 10 }} justify='center' align={'center'} vertical={true}>
            <h1>
                Geekenglish app
            </h1>
            <a href={Constants.GOOGLE_PLAY_LINK}>
                <IconPlayStore />
            </a>
            <a href={Constants.APPLE_STORE_LINK}>
                <IconAppStore />
            </a>
        </Flex>
    )
}