import React, { FC } from 'react'
import { Card, Radio, Typography, Flex } from 'antd'
import { ModuleTestExerciseDto } from '../../../../../shared/types/api'
import * as API_COURSES from '../../../../../shared/http/courses'

const { Text } = Typography


interface QuizQuestionProps {
    ex: ModuleTestExerciseDto,
    removeExercise: (exId: number) => void
}

const QuizQuestion: FC<QuizQuestionProps> = ({ ex, removeExercise }) => {

    const deleteTest = () => {
        if (ex.id) {
            API_COURSES.deleteTestExercise(ex.id).then(resp => {
                removeExercise(Number(ex.id))
            })
        }
    }

    return (
        <Card style={{ marginTop: 16 }}>

            <Flex gap={16}>
                <Text>{ex.data.title}</Text>
                {/* <a>Редактировать</a>*/}
                <a onClick={deleteTest}>Удалить</a>
            </Flex>
            <Radio.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: 16 }}>
                {
                    ex.data.answers.map((data) => (
                        <Radio value={data}>
                            {data.value}
                        </Radio>
                    ))}
            </Radio.Group>
        </Card>
    )
}

export default QuizQuestion