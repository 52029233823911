import { useEffect, useState } from 'react'
import { Checkbox, Form, Input, Spin } from 'antd'
import ValidateUser from '../../utils/ValidateUser'
import { useNavigate } from 'react-router-dom'
import TelegramLoginButton from '../../elements/buttons/TelegramLoginButton'
import '../../../styles/scss/mobile-auth/mobile-auth.scss'
import { fetchLogin } from '../../../store/global/extraReducers'
import * as API_USER from '../../../shared/http/user'
import { createFormValidationDebounce } from '../../utils/createFormValidationDebounce'
import { NavigationPages } from '../../../common/navigation/navigation-pages'
import { GEButton } from '../../../common/buttons'
import { handleHttpError } from '../../../common/utils/errors'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setLoadingRoutine, userSelector } from '../../../store/global'
import { goToPageNotPremium } from '../../utils/goToPage'
import { GELink } from '../../../common/link/GELink'
import { SuccessRegistrationModal } from '../../modalwindows/SuccessRegistrationModal'

const MobileAuth = (props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { type = 'login' } = props
    const currentUser = useAppSelector(userSelector)
    const [formType, setFormType] = useState({
        formTitle: 'Вход в аккаунт',
        type: 'login'
    })
    const [isRegistrationLoading, setIsRegistrationLoading] = useState(false)
    const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false)
    const [registeredUserEmail, setRegisteredUserEmail] = useState('')

    const handleLogin = (user) => dispatch(fetchLogin(user))


    const tryLogin = (user) => handleLogin(user)

    const handleRegister = (user) => {
        setIsRegistrationLoading(true)
        API_USER.registration(user)
            .then((resp) => {
                setRegisteredUserEmail(resp.data.email)
                setIsVisibleSuccessModal(true)
            })

            .catch((e) => {
                handleHttpError(e, navigate)
            })
            .finally(() => {
                dispatch(setLoadingRoutine(false))
                setIsRegistrationLoading(false)
            })
    }

    const onForgot = () => goToPageNotPremium(NavigationPages.RESET_PASSWORD, navigate)

    const debValidateLogin = createFormValidationDebounce((value) => API_USER.getLoginCoincidence(value), 1000)

    const onFinish = (e) => {
        if (formType.type === 'register') {
            handleRegister({
                referralUserId: null,
                login: e.registerLogin,
                email: e.registerEmail,
                password: e.registerPassword,
                passwordConfirm: e.registerPasswordConfirm,
                notificationFlag: e.notificationFlag
            })
        } else {
            tryLogin({
                login: e.signInLogin,
                password: e.signInPassword
            })
        }
    }

    const handleToRegister = () =>
        setFormType({
            formTitle: 'Регистрация',
            type: 'register'
        })

    const handleToLogin = () =>
        setFormType({
            formTitle: 'Вход в аккаунт',
            type: 'login'
        })

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    }

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 }
    }

    const renderFormLogin = () => (
        <>
            <Form.Item
                className={'mobil-auth-body__login-form-item'}
                name={'signInLogin'}
                label={'Логин или email'}
                rules={[{ required: true, message: 'Необходимо ввести логин или email' }]}
            >
                <Input placeholder={'Введите логин или email'} />
            </Form.Item>
            <Form.Item
                className={'mobil-auth-body__login-form-item'}
                name={'signInPassword'}
                label={'Пароль'}
                rules={[{ required: true, message: 'Необходимо ввести пароль' }]}
            >
                <Input.Password placeholder={'Введите пароль'} />
            </Form.Item>
        </>
    )

    const renderFormRegister = () => (
        <>
            <Form.Item
                className={'mobil-auth-body__registration-form-item'}
                name={'registerEmail'}
                label={'Электронная почта'}
                rules={[
                    { required: true, message: 'Необходимо ввести email' },
                    {
                        pattern: ValidateUser.regExpEmail,
                        message: ValidateUser.errorEmail
                    }
                ]}
            >
                <Input placeholder={'example@mail.ru'} />
            </Form.Item>
            <Form.Item
                className={'mobil-auth-body__registration-form-item'}
                name={'registerLogin'}
                label={'Логин'}
                validateFirst
                rules={[
                    { required: true, message: 'Необходимо ввести логин' },
                    {
                        pattern: ValidateUser.regExpLogin,
                        message: ValidateUser.errorLogin
                    },
                    {
                        validator(_, value) {
                            return new Promise((resolve, reject) => {
                                debValidateLogin(value).then((resp) => {
                                    if (resp) {
                                        return reject(new Error(ValidateUser.errorLoginCoincidence))
                                    }
                                    return resolve()
                                })
                            })
                        }
                    }
                ]}
            >
                <Input placeholder={'Придумайте логин'} />
            </Form.Item>
            <Form.Item
                className={'mobil-auth-body__registration-form-item'}
                name={'registerPassword'}
                label={'Пароль'}
                rules={[
                    { required: true, message: 'Пароли должны совпадать' },
                    {
                        pattern: ValidateUser.regExpPassword,
                        message: ValidateUser.errorValidPassword
                    }
                ]}
            >
                <Input.Password placeholder={'Введите пароль'} />
            </Form.Item>
            <Form.Item
                className={'mobil-auth-body__registration-form-item login__notification'}
                name='notificationFlag'
                valuePropName='checked'
            >
                <Checkbox>
                    <span className='login__notification__label'> Включить уведомления</span>
                </Checkbox>
            </Form.Item>
            <div className={'login__policy'}>
                Нажимая кнопку регистрации, Вы соглашаетесь с нашей
                <br />
                <GELink to='/personal-policy'> политикой конфиденциальности</GELink>,
                <br />
                а также безоговорочно и полностью принимаете условия
                <GELink to='/aferta'> Оферты</GELink>
            </div>
        </>
    )

    useEffect(() => {
        if (type === 'login') {
            setFormType({
                formTitle: 'Вход в аккаунт',
                type: 'login'
            })
        } else {
            setFormType({
                formTitle: 'Регистрация',
                type: 'register'
            })
        }
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    useEffect(() => {
        if (currentUser) {
            goToPageNotPremium(`${NavigationPages.PROFILE}/${currentUser.id}`, navigate)
        }
    }, [currentUser])

    return (
        <div id='mobil-auth-container' className='mobil-auth-container'>
            <div className='mobil-auth-body'>
                <Spin spinning={isRegistrationLoading}>
                    <h2 className='mobil-auth-body__title'>{formType.formTitle}</h2>
                    <Form
                        initialValues={{ notificationFlag: true }}
                        {...layout}
                        className={'mobil-auth-body__form'}
                        name={'login'}
                        onFinish={onFinish}
                    >
                        {formType.type === 'login' ? renderFormLogin() : renderFormRegister()}
                        <GEButton
                            className='mobil-auth-body__login-registration-btn'
                            {...tailLayout}
                            type={'primary'}
                            htmlType={'submit'}
                        >
                            {formType.type === 'login' ? 'Войти' : 'Регистрация'}
                        </GEButton>
                    </Form>
                    {window.location.hostname === 'geekenglish.ru' && (
                        <TelegramLoginButton className='telegram-btn' botName={'GeekenglishBot'} />
                    )}
                    {formType.type === 'register' ? (
                        <div className='mobil-auth-body__container-btn-registration'>
                            <button className='container-btn__login__btn' onClick={handleToLogin}>
                                Уже есть аккаунт? Войти
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className='mobil-auth-body__container-btn'>
                                <button className='container-btn__login__btn' onClick={handleToRegister}>
                                    У нас впервые? Зарегистрируйтесь!
                                </button>
                                <button className='container-btn__login__btn' onClick={onForgot}>
                                    Забыли пароль?
                                </button>
                            </div>
                        </>
                    )}
                </Spin>
            </div>
            <SuccessRegistrationModal isVisibleSuccessModal={isVisibleSuccessModal}
                                      closeSuccessModal={() => setIsVisibleSuccessModal(false)}
                                      registeredUserEmail={registeredUserEmail}
                                      containerId={'mobil-auth-container'} />
        </div>
    )
}

export default MobileAuth
