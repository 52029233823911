import { FC } from 'react'
import { Tooltip } from 'antd'

interface IconCoolVideoProps {
    className?: string,
    width?: number,
    height?: number,
    strokeWidth?: number,
    stroke?: string,
    tooltipText?: string
    onClick?: () => void
}

export const IconCoolVideo: FC<IconCoolVideoProps> = ({
                                                          width = 44,
                                                          height = 44,
                                                          strokeWidth = 2,
                                                          stroke = '#1890ff',
                                                          className = '',
                                                          onClick,
                                                          tooltipText
                                                      }) => {

    if (tooltipText) {
        return (
            <Tooltip title={tooltipText}>
                <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'
                     stroke={stroke} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round'>
                    <path d='M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z' />
                    <path d='M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z' />
                </svg>
            </Tooltip>
        )
    }

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'
             stroke={stroke} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round'>
            <path d='M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z' />
            <path d='M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z' />
        </svg>
    )
}
