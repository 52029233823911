import '../../../../styles/scss/courses/courses.scss'
import { Helmet } from 'react-helmet'
import { CoursesCard } from './CoursesCard'
import { useEffect, useState } from 'react'
import * as API_COURSES from '../../../../shared/http/courses'
import { SmallCourseDto } from '../../../../shared/types/api'
import Loading from '../../../elements/loading/Loading'
import { Flex } from 'antd'
import { NoAuthGEButton } from '../../../../common/buttons/no-auth-gebtn'
import { useNavigate } from 'react-router-dom'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import PrivilegesUtil from '../../../utils/PrivilegesUtil'
import Privileges from '../../../../const/Privileges'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'

export const Courses = () => {

    const navigate = useNavigate()
    const currentUser = useAppSelector(userSelector)


    const [loading, setLoading] = useState<boolean>()
    const [courses, setCourses] = useState<Array<SmallCourseDto>>([])

    useEffect(() => {
        setLoading(true)

        API_COURSES.getCourses().then((resp) => {
            setCourses(resp.content)
        }).finally(() => {
            setLoading(false)
        })

    }, [])


    const createCourse = () => {
        API_COURSES.createNewCourse('Новый курс').then(c => {
            const id = c.id
            goToPageNotPremium(`${NavigationPages.COURSE_EDIT}/${id}`, navigate)
        })
    }

    return (
        <div id='courses'>
            {loading && <Loading />}
            <Helmet title='Курсы английского языка'
                    meta={[
                        {
                            'name': 'description',
                            'content': 'Купите онлайн-курсы по английскому языку и учитесь в удобное время. Наши курсы подходят для всех уровней — от начинающих до продвинутых. Получите доступ к качественным материалам, видеоурокам и упражнениям, которые помогут вам быстро улучшить навыки. Учитесь в своём темпе и в удобном формате. Начните изучать английский уже сегодня!'
                        },
                        { 'property': 'og:type', 'content': 'product.group' }
                    ]} />
            <Flex justify={'flex-start'}>
                <NoAuthGEButton size={'large'} onClick={createCourse}>Создать курс</NoAuthGEButton>
            </Flex>

            <Flex gap={32} style={{ marginTop: 16 }} wrap={'wrap'}>
                {courses.map((card, key) => (
                    <CoursesCard card={card} key={card.id} />
                ))}
            </Flex>
        </div>
    )
}