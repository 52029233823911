import { FC } from 'react'

interface ArrowsRightLeftIconProps {
    className?: string,
    onClick?: () => void
}

export const ArrowsRightLeftIcon: FC<ArrowsRightLeftIconProps> = ({ className = '', onClick }) => {

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} viewBox='0 0 24 24' fill='none'
             stroke='currentColor' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round'>
            <path d='M21 7l-18 0' />
            <path d='M18 10l3 -3l-3 -3' />
            <path d='M6 20l-3 -3l3 -3' />
            <path d='M3 17l18 0' />
        </svg>

    )
}
