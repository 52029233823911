import '../../../../../styles/scss/courses/courses-overview-author.scss'
import { Avatar, Flex, Typography } from 'antd'
import { FC } from 'react'
import { CourseDto } from '../../../../../shared/types/api'
import IconYoutube from '../../../../elements/svg_icons/IconYoutube'
import IconTelegram from '../../../../elements/svg_icons/IconTelegram'
import Constants from '../../../../../const/Constants'
import UserUtil from '../../../../utils/UserUtil'


interface CourseOverviewAuthorProps {
    course: CourseDto
}

const { Paragraph, Text } = Typography

export const CourseOverviewAuthor: FC<CourseOverviewAuthorProps> = ({ course }) => {

    return (
        <Flex className='courses-overview-author__container' vertical>
            <span className='courses-overview-author__title'>Об авторе</span>
            <Flex vertical align='center' gap='16px'>
                <Avatar size={180}
                        src={<img src={`${Constants.queryConst}/file/profile/photo?id=` + course.courseAuthor?.photoId}
                                  alt='avatar' />} />
                {/*<img src={course.author.avatar} alt={course.name} className='courses-overview-author__img'/>*/}
                <div className='courses-overview-author__text'>{course.courseAuthor?.name}</div>
                <div className='courses-overview-author__text'>
                    <Paragraph ellipsis={{ rows: 5, expandable: true, symbol: 'далее' }}>
                        {course.courseAuthor?.description}
                    </Paragraph>
                </div>
                <div className='courses-overview-author__text'>
                    {/* <IconYoutube className={'pointer-cursor'} onClick={() => {
                        window && window.open(course.author.links.youTube, '_blank')
                    }} />
                    <IconTelegram className={'pointer-cursor'} onClick={() => {
                        window && window.open(course.author.links.telegram, '_blank')
                    }} />*/}
                </div>
            </Flex>
        </Flex>
    )
}