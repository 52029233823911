import { FC } from 'react'
import { Tooltip } from 'antd'

interface IconListDetailsProps {
    className?: string,
    width?: number,
    height?: number,
    strokeWidth?: number,
    stroke?: string,
    tooltipText?: string
    onClick?: () => void
}

export const IconListDetails: FC<IconListDetailsProps> = ({
                                                              width = 44,
                                                              height = 44,
                                                              strokeWidth = 2,
                                                              stroke = '#1890ff',
                                                              className = '',
                                                              onClick,
                                                              tooltipText
                                                          }) => {

    if (tooltipText) {
        return (
            <Tooltip title={tooltipText}>
                <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'
                     stroke={stroke} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round'>
                    <path d='M13 5h8' />
                    <path d='M13 9h5' />
                    <path d='M13 15h8' />
                    <path d='M13 19h5' />
                    <path d='M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
                    <path d='M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
                </svg>
            </Tooltip>
        )
    }

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' fill='none'
             stroke={stroke} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round'>
            <path d='M13 5h8' />
            <path d='M13 9h5' />
            <path d='M13 15h8' />
            <path d='M13 19h5' />
            <path d='M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
            <path d='M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
        </svg>
    )
}
