import { Helmet } from 'react-helmet'
import '../../../../styles/scss/course/course.scss'
import ReactPlayer from 'react-player'
import { GEButton } from '../../../../common/buttons'
import { Flex, Form, Progress, Button, message, Card, Divider } from 'antd'
import { CourseItem } from './CourseItem'
import { useEffect, useState } from 'react'
import * as API_COURSES from '../../../../shared/http/courses'
import {
    CourseDto,
    ModuleAudioExerciseDto,
    ModulesDto,
    ModuleTestExerciseDto
} from '../../../../shared/types/api'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../../elements/loading/Loading'
import { CourseTest } from './course-excercise/CourseTest'
import { ExerciseModuleType } from '../../../../common/enums/exercise-module-type'
import { CourseAudioPlayer } from './course-excercise/CourseAudioPlayer'
import { DownloadIcon } from '../../../elements/svg_icons/DownloadIcon'

export const Course = () => {

    const [course, setCourse] = useState<CourseDto | undefined>(undefined)
    const [activeModule, setActiveModule] = useState<ModulesDto | undefined>(undefined)
    const [completedModules, setCompletedModules] = useState<Array<number> | undefined>(undefined)
    const [courseLoading, setCourseLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    const { id } = useParams()

    const selectModule = (module: ModulesDto) => {
        setActiveModule(module)
    }

    const PREFIX = 'https://geekenglish.ru/api/geekenglish-stream/video/stream/mp4/'


    useEffect(() => {
        setCourseLoading(true)
        API_COURSES.getCourse(Number(id)).then((resp) => {
            setCourse(resp as CourseDto)
            setCompletedModules(resp.completedModules)
        }).catch(() => {
            goToPageNotPremium(`${NavigationPages.COURSES_OVERVIEW}/1`, navigate)
        }).finally(() => {
            setCourseLoading(false)
        })
    }, [])

    const passModule = () => {
        if (activeModule) {
            API_COURSES.passCourseModule(activeModule.id).then(c => {
                if (completedModules) {
                    setCompletedModules([...completedModules, activeModule.id])
                } else {
                    setCompletedModules([activeModule.id])
                }
            })
        }
    }


    const divWidth = document.getElementById('course__video_container')?.offsetWidth
    const videoWidth = divWidth ? divWidth * 0.98 : 300

    const progress = completedModules && course ? (completedModules.length / course.modules.length) * 100 : 0

    const onFinish = (values: any) => {
        message.success('Вы завершили тест')
    }

    const downloadFile = (fileId: number) => {
        if (activeModule) {
            API_COURSES.getCourseFile(fileId, activeModule.id).then((response) => {
                const content = response.headers.get('content-disposition')
                const parts = content!.split(';')
                const filename = parts[1].split('=')[1]
                response.blob().then((blob) => {
                    /*                   Todo*/
                    const data = new Blob([blob], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = filename
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                })
            })
        }
    }

    return (
        <div id='course'>
            <Helmet title='GE: Курс' />
            {course &&
            <Flex vertical gap='16px'>
                {activeModule ?
                    <Flex justify={'space-between'} align={'center'} wrap={'wrap'}>
                        <span className='course__module-title'>{activeModule.title}</span>
                        <GEButton onClick={passModule}>Отметить как изучено</GEButton>
                    </Flex>
                    : <span className='course__module-title'>Выберите модуль</span>}
                <Flex className='course__module-container'>
                    <Flex vertical gap='32px' className='course__video-container'>
                        <div className='course__module-video-container' id='course__video_container'>
                            {activeModule?.videoName && <ReactPlayer className='course__module-video' width={videoWidth}
                                                                     height={videoWidth * 9 / 16}
                                                                     controls={true}
                                                                     url={activeModule && PREFIX + activeModule.videoName}
                                                                     config={{
                                                                         file: {
                                                                             attributes: {
                                                                                 controlsList: 'nodownload'
                                                                             }
                                                                         }
                                                                     }} />}
                        </div>
                        {activeModule &&
                        <>

                            <div className='course__module-description'>{activeModule.description}</div>
                        </>}
                    </Flex>
                    {activeModule?.tests && <Divider style={{ borderColor: '#000000' }}>Тест к модулю</Divider>}
                    <Flex>
                        {activeModule?.tests &&
                        <Form
                            layout={'vertical'}
                            onFinish={onFinish}
                            name='validate_other'
                            style={{ maxWidth: '100%' }}
                            validateTrigger={['onSubmit']}
                        >

                            {activeModule.tests.map((ex) => {
                                return ex.type === ExerciseModuleType.TEST ?
                                    <CourseTest ex={ex as ModuleTestExerciseDto} /> :
                                    <CourseAudioPlayer ex={ex as ModuleAudioExerciseDto} />
                            })}
                            <Form.Item>
                                <Button type='primary' htmlType='submit' style={{ marginTop: 18, marginBottom: 18 }}>
                                    Проверить ответы
                                </Button>
                            </Form.Item>

                        </Form>
                        }
                    </Flex>
                    {activeModule?.filesInfo &&
                    <Divider style={{ borderColor: '#000000' }}>Материалы к модулю</Divider>}
                    <Flex gap={8} style={{ margin: 8 }}>
                        {activeModule?.filesInfo && activeModule.filesInfo.map((ex) => {
                            return <Card title={ex.name}>
                                <Flex justify={'center'} onClick={() => downloadFile(ex.id)}>
                                    <DownloadIcon className='pointer-cursor' />
                                </Flex>
                            </Card>
                        })}
                    </Flex>

                    <div className='course__list-container'>
                        <Flex vertical className='course__progress-container'>
                            <span className='course__progress-title'>{`Прогресс ${progress}%`}</span>
                            <Progress
                                percent={progress} />
                            <span className='course__progress-quote'>"Обучение - это двигатель профессионального успеха." - Джон Коттер</span>
                        </Flex>
                        <Flex vertical gap='8px'>
                            <Flex justify='space-between'>
                                <span className='course__module-title-list'>Все видео курса</span>
                                <span className='course__module-title-list'>1/10</span>
                            </Flex>
                            <Flex vertical gap='8px'>
                                {course.modules.map((module) => (
                                    <CourseItem selectModule={selectModule} module={module}
                                                activeModule={activeModule} completedModules={completedModules} />
                                ))}
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
            </Flex>
            }
            {courseLoading && <Loading />}
        </div>
    )
}