import { GEButton } from '../../../../../common/buttons'
import { FC, useState } from 'react'
import { ArrowsRightLeftIcon } from '../../../../elements/svg_icons/premium/ArrowsRightLeftIcon'
import { Tooltip } from 'antd'
import * as API_WORDLIST from '../../../../../shared/http/word-list'
import { fetchWords } from '../../../../../store/words/extraReducers'
import { useAppDispatch } from '../../../../../store/hooks'

interface SwitchLanguagesButtonProps {
    selectedCategory: any,
    wordsAmount: number,
}

const SwitchLanguagesButton: FC<SwitchLanguagesButtonProps> = ({
                                                                   selectedCategory,
                                                                   wordsAmount

                                                               }) => {
    const isVisible = selectedCategory !== null && wordsAmount > 0

    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const getWords = (id: number) => dispatch(fetchWords(id))

    const switchSidesWordList = () => {
        setLoading(true)
        API_WORDLIST.switchSidesWordList(selectedCategory.id as number).then(r => {
            getWords(selectedCategory.id).finally(() => {
                setLoading(false)
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div
            className={isVisible ? '' : 'ge-invisible'}
        >
            <Tooltip placement='topLeft' title='Поменять языки местами'>
                <GEButton icon={<ArrowsRightLeftIcon />} loading={loading} onClick={switchSidesWordList}/>
            </Tooltip>
        </div>
    )
}

export default SwitchLanguagesButton