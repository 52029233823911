import '../../../styles/css/blocks/wordlist_combine_selector.css'
import Constants from '../../../const/Constants'
import IconRefresh from '../../elements/svg_icons/IconRefresh'
import { CommunityDto, ID} from '../../../shared/types/api'
import { FC } from 'react'
import { Flex, Select, TreeSelect } from 'antd'
import useTreeSelector from '../../../hooks/useTreeSelector'
import {GELink} from "../../../common/link/GELink";

interface WordsListTestCombineSelectorProps {
    changeSide: (sideValue: string) => void,
    changeWordList: (id: number) => void,
    communities: Array<CommunityDto>,
    showWordListLoader: boolean,
    selectedWordListId?: ID,
    restartTest: () => void
}

const WordsListTestCombineSelector: FC<WordsListTestCombineSelectorProps> = ({
                                                                                 restartTest,
                                                                                 changeWordList,
                                                                                 communities,
                                                                                 showWordListLoader,
                                                                                 selectedWordListId,
                                                                                 changeSide
                                                                             }) => {
    const {
        onChange,
        onLoadData,
        value,
        treeData
    } = useTreeSelector(selectedWordListId, communities, changeWordList)

    return (
        <div id='wordlist-combine-selector'>
            <Flex vertical gap={10}>
                <TreeSelect
                    treeLine={true}
                    loading={showWordListLoader}
                    treeDataSimpleMode
                    style={{ width: '250px' }}
                    value={value}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder='Выберите список карточек'
                    onChange={onChange}
                    loadData={onLoadData}
                    treeData={treeData}
                />
                <Select
                    defaultValue={Constants.CARD_SIDE_ONE}
                    style={{ width: '250px' }}
                    onChange={changeSide}
                    options={[
                        { value: Constants.CARD_SIDE_ONE, label: 'Сторона 1' },
                        { value: Constants.CARD_SIDE_TWO, label: 'Сторона 2' }
                    ]}
                />
            </Flex>
            <button onClick={restartTest} className='flexbox-center-center'>
                <IconRefresh tooltipText={'Начать тест сначала'} />
            </button>
            <span className='wordlist-combine-selector__help'>
                Нет списков слов, добавьте его в <GELink to='/dictionary'>словаре</GELink> или возьмите
                <GELink to='/donewordslists'> готовый список</GELink>
            </span>
        </div>
    )
}

export default WordsListTestCombineSelector
