import { Flex, Modal } from 'antd'
import { FC, useEffect, useState } from 'react'
import { WordDto, WordList } from '../../shared/types/api'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { fetchWords } from '../../store/words/extraReducers'
import {
    clearNotSavedWordsRoutine,
    setWordsRoutine,
    wordsDataSelector,
    wordsIsisLoadingStatusSelector
} from '../../store/words'
import Loading from '../elements/loading/Loading'
import WordItem from '../pages/mainpages/dictionary/words/WordItem'
import WordsLangHeader from '../pages/mainpages/dictionary/words/WordsLangHeader'
import IconQuestion from '../elements/svg_icons/IconQuestion'
import InfoWordsDictionaryDialog from './InfoWordsDictionaryDialog'
import AddWordButton from '../pages/mainpages/dictionary/words/AddWordButton'
import AddWordsButton from '../pages/mainpages/dictionary/words/AddWordsButton'
import GenerateWordsButton from '../pages/mainpages/dictionary/words/GenerateWordsButton'
import WordUtils from '../utils/WordUtils'
import AddWordsDictionaryDialog from './AddWordsDictionaryDialog'
import GenerateWordsDictionaryDialog from './GenerateWordsDictionaryDialog'
import DateUtils from '../utils/DateUtils'
import AddAIWordsButton from '../pages/mainpages/dictionary/words/AddAIWordsButton'
import SwitchLanguagesButton from '../pages/mainpages/dictionary/words/SwitchLanguagesButton'

interface CardsDictionaryDialogProps {
    isOpen: boolean,
    canEditWordList: boolean,
    close: () => {},
    wordList: WordList
}

export const CardsDictionaryDialog: FC<CardsDictionaryDialogProps> = ({ wordList, isOpen, canEditWordList, close }) => {

    const words = useAppSelector(wordsDataSelector)
    const wordIsLoading = useAppSelector(wordsIsisLoadingStatusSelector)
    const dispatch = useAppDispatch()
    const fetchWordsByWordListIdRedux = (id: number) => dispatch(fetchWords(id))

    const [showAddWordInfo, setShowAddWordInfo] = useState<boolean>(false)
    const [showAddWordsModal, setShowAddWordsModal] = useState<boolean>(false)
    const [showGenerateWords, setShowGenerateWords] = useState<boolean>(false)

    const setWordsRedux = (w: Array<WordDto>) => dispatch(setWordsRoutine(w))

    const clearAndClose = () => {
        //@ts-ignore
        dispatch(clearNotSavedWordsRoutine())
        close()
    }

    /**
     * Загрузить все слова по id списка слов
     * @param id списка слов
     */
    const loadWords = (id: number) => {
        fetchWordsByWordListIdRedux(id)
    }

    useEffect(() => {
        loadWords(wordList.id as number)
    }, [])

    const openAddWordInfo = () => {
        setShowAddWordInfo(true)
    }

    const closeAddWordInfo = () => {
        setShowAddWordInfo(false)
    }

    const closeAddWordsModal = () => {
        setShowAddWordsModal(false)
    }

    const openAddWordsModal = () => {
        setShowAddWordsModal(true)
    }

    /**
     * Добавить новое слово
     */
    const addWord = () => {
        const newWord = WordUtils.createNewWord(wordList.id)
        setWordsRedux([...words, newWord])
    }

    const selectedWords = words.filter((w: WordDto) => w.listId === wordList?.id)
    const wordsCount = selectedWords.length
    const savedWordsCount = selectedWords.filter((sw: WordDto) => !WordUtils.isNewEntity(sw.id)).length

    return (
        <Modal
            width={800}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            title={`${wordList.listName} (${selectedWords.length})`} onCancel={clearAndClose} open={isOpen}
        >
            {wordIsLoading ? <Loading /> : ''}
            <IconQuestion onClick={openAddWordInfo} className=' pointer-cursor' />

            <Flex gap='16px' className='modal-word-card__button-area'>
                {canEditWordList && <>
                    <AddWordButton
                        addWord={addWord}
                        selectedCategory={wordList}
                        wordsAmount={wordsCount}
                    />
                    <AddAIWordsButton
                        selectedCategory={wordList}
                        wordsAmount={savedWordsCount}
                    />
                    <AddWordsButton
                        selectedCategory={wordList}
                        wordsAmount={wordsCount}
                        showAddWordsModal={openAddWordsModal}
                    />
                    <GenerateWordsButton
                        selectedCategory={wordList}
                        wordsAmount={wordsCount}
                        showAddWordsModal={() => setShowGenerateWords(true)}
                    />
                    <SwitchLanguagesButton
                        selectedCategory={wordList}
                        wordsAmount={wordsCount}
                    />
                </>}
            </Flex>
            <WordsLangHeader
                canEdit={canEditWordList}
                activeWordList={wordList}
                wordCount={selectedWords.length}
            />

            {selectedWords.sort((a: WordDto, b: WordDto) => DateUtils.compareDesc(a.created, b.created)).map((word: WordDto) => (
                <WordItem
                    key={word.id}
                    wordItem={word}
                    cardList={wordList}
                    canEditWord={canEditWordList}
                    loadWords={loadWords}
                />
            ))}
            <InfoWordsDictionaryDialog cancelWindow={closeAddWordInfo} open={showAddWordInfo} />
            <AddWordsDictionaryDialog
                open={showAddWordsModal}
                wordsAmount={wordsCount}
                activeWordList={wordList}
                cancelWindow={closeAddWordsModal}
                loadWords={(id) => loadWords(wordList.id as number)}
            />
            <GenerateWordsDictionaryDialog
                open={showGenerateWords}
                activeWordList={wordList}
                cancelWindow={() => setShowGenerateWords(false)}
            />
        </Modal>
    )
}


