import React, { FC } from 'react'
import { Form, Radio, Space, Typography } from 'antd'
import { ModuleTestExerciseDto } from '../../../../../shared/types/api'


const { Text } = Typography

interface AudioPlayerProps {
    ex: ModuleTestExerciseDto,
}

export const CourseTest: FC<AudioPlayerProps> = ({ ex }) => {

    return (
        <Form.Item
            name={ex.id}
            label={ex.data.title}
            rules={[
                {
                    validator(_, value) {
                        const answers = ex.data.answers
                        const find = answers.find(a => a.correct)
                        return new Promise((resolve, reject) => {
                            if (find?.value === value) {
                                return resolve('ok')
                            } else {
                                const erMsg = ex.data.comment ? ex.data.comment : 'Неверный вариант'
                                return reject(new Error(erMsg))
                            }
                        })
                    }
                }
            ]}
        >
            <Radio.Group>
                <Space direction='vertical'>
                    {ex.data.answers.map((entry, index) => (
                        <Radio value={entry.value}>{entry.value}</Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Form.Item>
    )
}

