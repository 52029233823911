import React, { FC } from 'react'
import { Button, Card, Flex, Typography } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { ModuleAudioExerciseDto } from '../../../../../shared/types/api'
import * as API_COURSES from '../../../../../shared/http/courses'


const { Text } = Typography

interface AudioPlayerProps {
    ex: ModuleAudioExerciseDto,
    removeExercise: (exId: number) => void
}

export const AudioPlayer: FC<AudioPlayerProps> = ({ ex, removeExercise }) => {
    const playAudio = () => {
        API_COURSES.playAudioExercise(Number(ex.id))
        //audio.play()
    }

    const deleteAudio = () => {
        if (ex.id) {
            API_COURSES.deleteTestExercise(ex.id).then(resp => {
                removeExercise(Number(ex.id))
            })
        }
    }

    return (
        <Card style={{ marginTop: 16 }}>
            <Text>{ex.data.title}</Text>
            <Flex align={'center'} gap={16}>
                <Button
                    type='default'
                    icon={<PlayCircleOutlined />}
                    onClick={playAudio}
                >
                    Прослушать аудио
                </Button>
                {/* <a>Редактировать</a>*/}
                <a onClick={deleteAudio}>Удалить</a>
            </Flex>

        </Card>
    )
}

