import { ApiError } from '../../common/error/api-error'

/**
 * Сохранение видео
 * @method getCourses
 * @return {Response} Fetch Response
 */
export async function saveVideo(formData: any, fileName: string, folder: string, chunkIndex: number, totalChunks: number) {
    await fetch(`/api/geekenglish-stream/video/upload-chunk?fileName=${fileName}&folder=${folder}&chunkIndex=${chunkIndex}&totalChunks=${totalChunks}`, {
        method: 'POST',
        body: formData
    }).then(resp => {
        if (resp.status !== 200) {
            throw new ApiError('Неизвестная ошибка', 'Неизвестная ошибка')
        }
    })
}