import { useEffect, useState } from 'react'
import '../../../../styles/scss/communities/communities.scss'
import { CommunityItem } from './CommunityItem'
import * as API_COMMUNITY from '../../../../shared/http/community'
import { CommunityDto } from '../../../../shared/types/api'
import Loading from '../../../elements/loading/Loading'
import CommunityUtils from '../../../utils/CommunityUtils'

export const PublicCommunities = () => {

    const [mainLoading, setMainLoading] = useState<boolean>(false)
    const [communities, setCommunities] = useState<Array<CommunityDto>>([])

    const loadPubLicCommunities = () => {
        setMainLoading(true)
        API_COMMUNITY.findAllPublicCommunityUserNotHas()
            .then((data) => {
                setCommunities(data)
            })
            .catch(() => {

            }).finally(() => {
            setMainLoading(false)
        })
    }

    useEffect(() => {
        loadPubLicCommunities()
    }, [])

    const updateCommunity = (c: CommunityDto) => {
        const newCommunities = communities.map((com) => {
            if (com.id === c.id) {
                return CommunityUtils.parseCommunity(c)
            }
            return com
        })
        setCommunities(newCommunities)
    }


    return (
        <div className='communities__main'>
            {mainLoading && <Loading />}
            {!mainLoading && communities.length === 0 && <span>Похоже сообществ пока нет</span>}
            {communities.map((item, i) => (
                <CommunityItem userHas={false} item={item} i={item.id} reload={loadPubLicCommunities}
                               updateCommunity={updateCommunity} />
            ))}
        </div>
    )
}