import '../../../../../styles/scss/course/create-course.scss'
import { Flex, Tabs, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import MainPageCreateCourse from './MainPageCreateCourse'
import CreateCourseModule from './CreateCourseModule'
import { useNavigate, useParams } from 'react-router-dom'
import * as API_COURSES from '../../../../../shared/http/courses'
import { CourseDto } from '../../../../../shared/types/api'
import Loading from '../../../../elements/loading/Loading'
import { GEButton } from '../../../../../common/buttons'
import { goToPageNotPremium } from '../../../../utils/goToPage'
import { NavigationPages } from '../../../../../common/navigation/navigation-pages'
import { notifyTelegramBot } from '../../../../../shared/http/telegram-notification'
import Link from 'antd/es/typography/Link'


type TargetKey = React.MouseEvent | React.KeyboardEvent | string;


export const CreateCourseFull = () => {

    const [api, contextHolder] = notification.useNotification()

    const [course, setCourse] = useState<CourseDto>()
    const [activeKey, setActiveKey] = useState('0')
    const [items, setItems] = useState<Array<any>>([])
    const { id } = useParams()
    const navigate = useNavigate()


    useEffect(() => {
        loadCourseFully()
    }, [])

    const loadCourseFully = () => {
        API_COURSES.getCourse(Number(id)).then((resp) => {
            setCourse(resp)
            const newPanes = []
            newPanes.push({
                label: 'Основная информация',
                children: <MainPageCreateCourse course={resp} refresh={loadCourseFully} />,
                key: '0',
                closable: false
            })

            if (resp.modules?.length > 0) {
                for (const module of resp.modules) {
                    const newActiveKey = '' + module.id
                    newPanes.push({
                        label: module.title,
                        children: <CreateCourseModule cModule={module} changeModuleName={changeModuleName} />,
                        key: newActiveKey,
                        closable: true
                    })
                }
            }
            setItems(newPanes)

        }).finally(() => {

        })
    }

    const newTabIndex = useRef(0)


    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey)
    }


    const add = () => {
        const newPanes = [...items]
        API_COURSES.createNewModule(Number(id), 'Модуль ' + (newPanes.length)).then((resp) => {
            newPanes.push({
                label: 'Модуль ' + (newPanes.length),
                children: <CreateCourseModule cModule={resp} changeModuleName={changeModuleName} />,
                key: `${resp.id}`,
                closable: true
            })
            setItems(newPanes)
            setActiveKey(`${resp.id}`)
        }).finally(() => {

        })
    }


    const remove = (targetKey: TargetKey) => {
        API_COURSES.deleteCourseModule(targetKey as string).then(() => {
            let newActiveKey = activeKey
            let lastIndex = -1
            items.forEach((item, i) => {
                if (item.key === targetKey) {
                    lastIndex = i - 1
                }
            })
            const newPanes = items.filter((item) => item.key !== targetKey)
            if (newPanes.length && newActiveKey === targetKey) {
                if (lastIndex >= 0) {
                    newActiveKey = newPanes[lastIndex].key
                } else {
                    newActiveKey = newPanes[0].key
                }
            }
            setItems(newPanes)
            setActiveKey(newActiveKey)
        })
    }

    const changeModuleName = (key: string, name: string) => {
        const bookmarks = [...items]
        const map = items.map(f => {
            if (f.key === key) {
                return {
                    ...f,
                    label: name
                }
            }
            return f
        })
        //setItems(map)
    }

    const backToMain = () => {
        goToPageNotPremium(`${NavigationPages.COURSES_LIST}`, navigate)
    }


    const onEdit = (
        targetKey: React.MouseEvent | React.KeyboardEvent | string,
        action: 'add' | 'remove'
    ) => {
        if (action === 'add') {
            add()
        } else {
            remove(targetKey)
        }
    }

    const publishCourse = () => {
        api.open({
            message: 'Вы успешно отправили курс на проверку',
            description:
                'После проверки ваш курс будет доступен пользователям сайта! Перейдите в канал для преподавателей чтобы следить за статусом вашего курса',
            duration: 0
        })
        notifyTelegramBot('Пользователь опубликовал курс ' + course?.id)
    }

    return (
        <div id='create-course'>
            {contextHolder}
            {!course && <Loading />}
            {course && items && items.length > 0 &&
            <div style={{ maxWidth: 800, margin: '0 auto', padding: '20px', backgroundColor: '#e9e9e9' }}>
                <Flex justify={'space-between'} style={{ padding: 8 }}> <
                    GEButton onClick={backToMain} type={'primary'}>Назад</GEButton>
                    <Link href={'https://t.me/+c3KBxXUukFUwMDM6'}>Телеграм канал для преподавателей</Link>
                    <GEButton onClick={publishCourse} type={'primary'}>Опубликовать курс</GEButton>
                </Flex>

                <Tabs
                    type='editable-card'
                    onChange={onChange}
                    activeKey={activeKey}
                    onEdit={onEdit}
                    items={items}
                />
            </div>}
        </div>
    )
}