import {FC} from 'react'

interface IconAlarmProps {
    className?: string,
    onClick?: () => void
}

export const IconAlarm: FC<IconAlarmProps> = ({className = '', onClick}) => {

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='60'
            height='60'
            fill='none'
            stroke='#ff9300'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-alarm'
            viewBox='0 0 24 24'
        >
            <title>Скоро повторять</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <circle cx='12' cy='13' r='7'></circle>
            <path d='M12 10L12 13 14 13'></path>
            <path d='M7 4L4.25 6'></path>
            <path d='M17 4L19.75 6'></path>
        </svg>

    )
}
