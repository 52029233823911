import React, { FC, useState } from 'react'
import { Form, Input, Button, Col, Card, Row, Typography, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import {
    AudioOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons'
import QuizQuestion from './QuizQuestion'
import { AudioPlayer } from './AudioPlayer'
import CreateCourseTestDialog from '../../../../modalwindows/course/CreateCourseTestDialog'
import CreateCourseAudioDialog from '../../../../modalwindows/course/CreateCourseAudioDialog'
import {
    ModuleAudioExerciseDto,
    ModuleExerciseDto,
    ModulesDto,
    ModuleTestExerciseDto
} from '../../../../../shared/types/api'
import { IconCoolVideo } from '../../../../elements/svg_icons/IconCoolVideo'
import { IconBook } from '../../../../elements/svg_icons/IconBook'
import { ExerciseModuleType } from '../../../../../common/enums/exercise-module-type'
import * as API_COURSES from '../../../../../shared/http/courses'
import * as API_STREAM_COURSES from '../../../../../shared/http/stream-service'
import { v4 as uuidv4 } from 'uuid'

const { TextArea } = Input

interface CreateCourseModuleProps {
    cModule: ModulesDto,
    changeModuleName: (key: string, name: string) => void
}

const CreateCourseModule: FC<CreateCourseModuleProps> = ({ cModule, changeModuleName }) => {


    const initVideoFile = cModule?.videoName ?
        [{
            uid: cModule.videoName.split('/')[1],
            name: cModule.videoName.split('/')[1],
            status: 'done',
            url: 'http://www.baidu.com/xxx.png'
        }] : []


    const exers = cModule?.tests ? cModule.tests : []
    const filesInfo = cModule?.filesInfo ? cModule.filesInfo.map(f => {
        return {
            uid: f.id,
            name: f.name,
            status: 'done',
            url: 'http://www.baidu.com/xxx.png'
        }
    }) : []


    const [showCreateTestDialog, setShowCreateTestDialog] = useState<boolean>(false)
    const [showCreateAudioDialog, setShowCreateAudioDialog] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [exercises, setExercises] = useState<Array<ModuleExerciseDto>>(exers)
    const [docsFileList, setDocsFileList] = useState<Array<any>>(filesInfo)
    const [videoFile, setVideoFile] = useState<Array<any>>(initVideoFile)


    const { Title } = Typography
    // Функция обработки отправки формы
    const onFinish = (values: any) => {
        setLoading(true)
        const updatedModule = {
            id: cModule.id,
            title: values.title,
            description: values.description,
            moduleId: cModule.id,
            video: false
        }

        API_COURSES.editModule(updatedModule as ModulesDto)
            .then(resp => {
                changeModuleName(String(cModule.id), values.title)
            }).finally(() => {

            setLoading(false)
        })
    }

    const addExercise = (exercise: ModuleExerciseDto) => {
        const newEx = [...exercises, exercise]
        setExercises(newEx)
    }

    const removeExercise = (exId: number) => {
        const newEx = exercises.filter(e => e.id != exId)
        setExercises(newEx)
    }

    const extracted = (totalChunks: number, chunkSize: number, file: any, folderAndFileNameArr: any) => {
        (async () => {
            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                const start = chunkIndex * chunkSize
                const end = Math.min(file.size, start + chunkSize)

                const chunk = file.slice(start, end) // Извлекаем часть файла
                const formData = new FormData()
                formData.append('file', chunk)

                try {
                    const pe = (chunkIndex / totalChunks) * 100
                    if (videoFile && videoFile.length > 0) {
                        const map = videoFile.map(f => {
                            if (f.uid === folderAndFileNameArr[1]) {
                                return {
                                    ...f,
                                    status: chunkIndex + 1 != totalChunks ? 'uploading' : 'done',
                                    percent: pe
                                }
                            }
                            return f
                        })
                        setVideoFile(map)
                    } else {
                        const newVar = [{
                            uid: folderAndFileNameArr[1],
                            name: folderAndFileNameArr[1],
                            status: chunkIndex + 1 != totalChunks ? 'uploading' : 'done',
                            url: 'http://www.baidu.com/xxx.png',
                            percent: pe
                        }]
                        setVideoFile(newVar)
                    }
                    // Ожидаем завершения загрузки текущего чанка
                    await API_STREAM_COURSES.saveVideo(
                        formData,
                        folderAndFileNameArr[1],
                        folderAndFileNameArr[0],
                        chunkIndex,
                        totalChunks
                    )
                } catch (error) {
                    message.error(`Failed to upload chunk ${chunkIndex + 1}`)
                    break // Прекращаем выполнение цикла в случае ошибки
                }
            }
        })()
    }

    const uploadVideFile = async (file: any) => {
        API_COURSES.createFileName(cModule.id, 'mp4').then(resp => {
            const folderAndFileName = resp.value
            const folderAndFileNameArr = folderAndFileName.split('/')
            const chunkSize = 20 * 1024 * 1024 // Размер части (20 MB)
            const totalChunks = Math.ceil(file.size / chunkSize)
            const newVar = [{
                uid: folderAndFileNameArr[1],
                name: folderAndFileNameArr[1],
                status: 'uploading',
                percent: 0
            }]
            setVideoFile(newVar)
            extracted(totalChunks, chunkSize, file, folderAndFileNameArr)
        })

    }

    const beforeUploadVideFile = (file: any) => {
        const isValid = file.type === 'video/mp4'
        if (!isValid) {
            message.error(`${file.name} поддерживаем только формат видео mp4`)
            return false
        }
        return true
    }

    const beforeUploadDocs = (file: any) => {
        const isValid = file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
           if (!isValid) {
               message.error(`${file.name} поддерживаем только pdf, pptx форматы`)
               return false
           }
        return true
    }

    const uploadDocs = (info: any) => {
        const uid = uuidv4()
        const newVar = [...docsFileList, {
            uid: uid,
            name: info.file.name,
            status: 'uploading',
            percent: 50
        }]
        setDocsFileList(newVar)
        API_COURSES.uploadDoc(cModule.id, info.file).then((resp) => {
            const map = newVar.map(f => {
                if (f.uid === uid) {
                    return {
                        ...f,
                        uid: resp.data.id,
                        status: 'done'
                    }
                }
                return f
            })
            setDocsFileList(map)
        }).catch(e => {
            const map = newVar.map(f => {
                if (f.uid === uid) {
                    return {
                        ...f,
                        status: 'error',
                        response: 'Не удалось сохранить файл'
                    }
                }
                return f
            })
            setDocsFileList(map)
        })
    }

    const deleteDocFile = (file: any) => {
        API_COURSES.deleteDoc(file.uid, cModule.id).then(r => {
            setDocsFileList(docsFileList.filter(f => f.uid != file.uid))
        })
    }

    const deleteVideoFile = () => {
        API_COURSES.deleteVideoFile(videoFile[0].name, cModule.id).then(r => {
            setVideoFile([])
        })
    }

    return (
        <div>
            <h1>Добавление модуля</h1>
            <Form
                layout='vertical'
                onFinish={onFinish}
                initialValues={{
                    title: cModule.title,
                    description: cModule.description
                }}
            >
                <Form.Item
                    label='Название модуля'
                    name='title'
                    rules={[{ required: true, message: 'Пожалуйста, введите название модуля' }]}
                >
                    <Input size={'large'} />
                </Form.Item>
                <h2>Видео</h2>
                <Dragger
                    fileList={videoFile}
                    multiple={false}
                    beforeUpload={beforeUploadVideFile}
                    customRequest={({ file, onSuccess, onError }) => {
                        uploadVideFile(file)
                            .then(() => console.log('ok'))
                            .catch((error) => console.log(error))
                    }}
                    onRemove={deleteVideoFile}
                >
                    <p className='ant-upload-drag-icon'>
                        <IconCoolVideo />
                    </p>
                    <p className='ant-upload-text'>Видео модуля</p>
                    <p className='ant-upload-hint'>
                        Поддерживаем формат видео mp4
                    </p>
                </Dragger>
                <h2>Материалы
                </h2>
                <Dragger
                    fileList={docsFileList}
                    multiple={true}
                    beforeUpload={beforeUploadDocs}
                    customRequest={uploadDocs}
                    onRemove={deleteDocFile}
                >
                    <p className='ant-upload-drag-icon'>
                        <IconBook />
                    </p>
                    <p className='ant-upload-text'>Материалы модуля</p>
                    <p className='ant-upload-hint'>
                        Поддерживаем pdf или pptx
                    </p>
                </Dragger>


                <Form.Item
                    label='Описание модуля'
                    name='description'
                    rules={[{ required: true, message: 'Пожалуйста, введите описание модуля' }]}
                >
                    <TextArea size='large' rows={4} placeholder='Введите краткое описание модуля' />
                </Form.Item>

                <Row gutter={[12, 12]} justify='center' style={{ marginTop: '30px' }}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Card bordered={false} style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => setShowCreateTestDialog(true)}>
                            <QuestionCircleOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
                            <Title level={4}>Добавить тест</Title>
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Card bordered={false} style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => setShowCreateAudioDialog(true)}
                        >
                            <AudioOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
                            <Title level={4}>Добавить аудио</Title>
                        </Card>
                    </Col>
                </Row>


                {exercises.length > 0 && exercises.map((ex, key) => {
                    if (ex.type === ExerciseModuleType.TEST) {
                        return <QuizQuestion ex={ex as ModuleTestExerciseDto} removeExercise={removeExercise}
                                             key={key} />
                    } else {
                        return <AudioPlayer ex={ex as ModuleAudioExerciseDto} removeExercise={removeExercise}
                                            key={key} />
                    }
                })}

                <CreateCourseAudioDialog cModule={cModule} addExercise={addExercise}
                                         close={() => setShowCreateAudioDialog(false)}
                                         isOpen={showCreateAudioDialog} />
                <CreateCourseTestDialog cModule={cModule} addExercise={addExercise}
                                        close={() => setShowCreateTestDialog(false)}
                                        isOpen={showCreateTestDialog} />

                <Form.Item>
                    <Button
                        style={{ marginTop: 16 }}
                        htmlType='submit'
                        loading={loading}
                    >
                        Сохранить модуль
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CreateCourseModule
