import '../../../../../styles/scss/courses/courses-overview-header.scss'
import { GEButton } from '../../../../../common/buttons'
import { Flex } from 'antd'
import { FC, useState } from 'react'
import { CourseDto } from '../../../../../shared/types/api'
import { useNavigate } from 'react-router-dom'
import { goToPageNotPremium } from '../../../../utils/goToPage'
import { NavigationPages } from '../../../../../common/navigation/navigation-pages'
import { robokassaPay } from '../../../../../shared/http/purchases'
import CoursePayLoadingDialog from '../../../../modalwindows/CoursePayLoadingDialog'
import { NoAuthGEButton } from '../../../../../common/buttons/no-auth-gebtn'
import Constants from '../../../../../const/Constants'

interface CourseOverviewHeaderProps {
    course: CourseDto,
}

export const CourseOverviewHeader: FC<CourseOverviewHeaderProps> = ({ course }) => {

    const [paymentId, setPaymentId] = useState<undefined | number>(1)
    const [openLoadingDialog, setOpenLoadingDialog] = useState<boolean>(false)
    const imageUrl = course.headerImage ? `${Constants.queryConst}/courses/${course.headerImage}/image/header` : '../../images/tmp/course1.jpg'


    const navigate = useNavigate()

    const openCourse = () => {
        goToPageNotPremium(`${NavigationPages.COURSES}/${course.id}`, navigate)
    }

    const buyCourse = () => {
        robokassaPay(course.id).then(data => {
            window.open(data.data.url)
            setPaymentId(data.data.paymentId)
            setOpenLoadingDialog(true)
        })
    }


    return (
        <Flex className='courses-overview-header__container'>
            <div className='courses-overview-header__content'>
                <h1 className='courses-overview-header__card-name'>{course.title}</h1>
                <Flex gap='8px'>

                    <span className='courses-overview-header__price-text'>Стоимость курса</span>
                    {course.price === course.oldPrice ?
                        <div className='courses-overview-header__price-text price'>{course.price}</div> :
                        <>
                            <div className='courses-overview-header__price-text price'>{course.price}</div>
                            <div className='courses-overview-header__price-text oldPrice'>{course.oldPrice}</div>
                        </>
                    }
                </Flex>
                {course.userHas ?
                    <GEButton onClick={openCourse} type='primary' className='courses-overview-header__buy'>Смотреть курс
                    </GEButton> : <NoAuthGEButton disabled={true} onClick={() => {
                    }} type='primary' className='courses-overview-header__buy'>Купить
                        курс (в разработке)</NoAuthGEButton>}
            </div>
            <img src={imageUrl} alt={course.title} className='courses-overview-header__img' />
            <CoursePayLoadingDialog open={openLoadingDialog} paymentId={paymentId} closeDialog={() => {
                setOpenLoadingDialog(false)
            }} />
        </Flex>
    )
}