import { FC } from 'react'

interface VideoSectionProps {
    title: string,
    gifLink: string
}

//Todo link
export const VideoSection: FC<VideoSectionProps> = ({ title, gifLink }) => {
    return (
        <section className='premium__video-area'>
            <div className='premium__video-content'>
                {/*    <ReactPlayer controls={false}
                             loop={true}
                             playing={true}
                             muted={true}
                             url={gifLink}
                />*/}
                <video src={gifLink} width='100%' height='100%'
                       autoPlay={true} muted={true} preload='auto' loop={true}>
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className='premium__video-title'>
                <span>{title}</span>
            </div>
        </section>
    )
}