import {
    executeDeleteMethod,
    executeFilePostMethod, executeGetMethod,
    executeJsonGetMethod, executeJsonPostMethod, executeJsonPutMethod
} from '../api/rest/restApiUtils'
import { CourseDto, ModuleExerciseDto, ModulesDto } from '../types/api'

/**
 * Получение курсов
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function getCourses() {
    return executeJsonGetMethod('/courses').then((resp) => {
        return resp.data
    })
}


/**
 * Получение курса по ID c видео материалами
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function getCourse(id: number) {
    return executeJsonGetMethod('/courses/' + id).then((resp) => {
        return resp.data
    })
}


/**
 * Получение курса по ID c видео материалами
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function getCourseOverview(id: number) {
    return executeJsonGetMethod('/courses/' + id + '/overview').then((resp) => {
        return resp.data
    })
}

/**
 * Получение прикрепленного файла к модулю
 * @method getCourseFile
 * @return {Response} Fetch Response
 */
export function getCourseFile(fileId: number, moduleId: number) {
    return executeGetMethod(`/course/modules/${moduleId}/files/${fileId}`)
}

/**
 * Отметить модуль как изученный
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function passCourseModule(id: number) {
    return executeJsonPostMethod('/course/modules/' + id + '/complete').then((resp) => {
        return resp.data
    })
}

/**
 * Получение курсов
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function createNewCourse(title: string) {
    return executeJsonPostMethod('/courses/create', { title: 'Название курса' }).then((resp) => {
        return resp.data
    })
}

/**
 * Создание модуля курса
 * @method createModule
 * @return {Response} Fetch Response
 */
export function createNewModule(courseId: number, title: string) {
    return executeJsonPostMethod(`/courses/${courseId}/modules/create`, { title: title }).then((resp) => {
        return resp.data
    })
}

/**
 * Создание модуля курса
 * @method createModule
 * @return {Response} Fetch Response
 */
export function deleteCourseModule(moduleId: string | number) {
    return executeDeleteMethod(`/course/modules/${moduleId}`)
}


export function editCourse(courseId: number, upd: CourseDto) {
    return executeJsonPutMethod(`/courses/${courseId}/edit`, upd).then((resp) => {
        return resp.data
    })
}

export function editModule(upd: ModulesDto) {
    return executeJsonPutMethod(`/course/modules/edit`, upd).then((resp) => {
        return resp.data
    })
}

export function createFileName(moduleId: number, extention: string) {
    return executeJsonPostMethod(`/course/modules/${moduleId}/videoName?extension=${extention}`).then((resp) => {
        return resp.data
    })
}

export function uploadImage(courseId: number, photo: any) {
    let formData = new FormData()
    formData.append('photo', photo)
    return executeFilePostMethod(`/courses/${courseId}/image/header`, formData)
}

export function deleteHeaderImage(courseId: number) {
    return executeDeleteMethod(`/courses/${courseId}/image/header`)
}

export function uploadDoc(moduleId: number, photo: any) {
    let formData = new FormData()
    formData.append('file', photo)
    return executeFilePostMethod(`/course/modules/${moduleId}/file`, formData).then((response) => {
        const json = response.json()
        return json
    })
}

export function deleteDoc(fileId: number, moduleId: number) {
    return executeDeleteMethod(`/course/modules/${moduleId}/files/${fileId}`)
}

export function deleteVideoFile(fileName: string, moduleId: number) {
    const fieNameReal = moduleId + '/' + fileName
    return executeDeleteMethod(`/course/modules/${moduleId}/video?fileName=${fieNameReal}`)
}


export function addExercise(data: ModuleExerciseDto) {
    return executeJsonPostMethod(`/course/modules/exercise/tests`, data).then((resp) => {
        return resp.data
    })
}

export function addAudiExercise(file: any, moduleId: number, title: string) {
    let formData = new FormData()
    formData.append('file', file)
    return executeFilePostMethod(`/course/modules/${moduleId}/exercise/audio?title=${title}`, formData).then((resp) => {
        const json = resp.json()
        return json
    })
}

export function deleteTestExercise(exId: number) {
    return executeDeleteMethod(`/course/modules/exercise/tests/${exId}`)
}

/**
 * Воспроизвести аудио
 * @method playCard
 * @param  {string}  audioId - язык
 * @return {Response} Fetch Response
 */
export function playAudioExercise(audioId: number) {
    return executeGetMethod(
        `/course/modules/exercise/audio/${audioId}`
    )
        .then((response) => response.blob())
        .then((blob) => {
            const url = URL.createObjectURL(blob)
            new Audio(url).play()
        })
        .catch((err) => console.error(err))
}
