import { Helmet } from 'react-helmet'
import '../../../../../styles/scss/courses/courses-overview.scss'
import { CourseOverviewHeader } from './CourseOverviewHeader'
import { Collapse, Flex } from 'antd'
import { CourseOverviewAuthor } from './CourseOverviewAuthor'
import { CourseOverviewDetails } from './CourseOverviewDetails'
import { useEffect, useState } from 'react'
import { CourseDto, ModulesDto } from '../../../../../shared/types/api'
import * as API_COURSES from '../../../../../shared/http/courses'
import Loading from '../../../../elements/loading/Loading'
import { IconCoolVideo } from '../../../../elements/svg_icons/IconCoolVideo'
import { useParams } from 'react-router-dom'
import { IconFileText } from '../../../../elements/svg_icons/IconFileText'
import { IconListDetails } from '../../../../elements/svg_icons/IconListDetails'

export const CourseOverview = () => {

    const [course, setCourse] = useState<CourseDto>()
    const [courseModules, setCourseModules] = useState<any>()
    const { id } = useParams()

    useEffect(() => {
        API_COURSES.getCourseOverview(Number(id)).then((resp) => {

            const modules = resp?.modules?.map((m: ModulesDto) => {
                return {
                    key: m.id,
                    label: <Flex justify={'space-between'}>
                        <p style={{ fontSize: 20 }}>{m.title}</p>
                        <Flex gap={8}>
                            {m.hasVideo && <IconCoolVideo width={24} height={24} strokeWidth={1.5} stroke={'#fff'}
                                                          tooltipText={'Модуль содержит видео материалы'} />}
                            {m.hasFiles && <IconFileText width={24} height={24} strokeWidth={1.5} stroke={'#fff'}
                                                         tooltipText={'Модуль содержит файлы с материалами'} />}
                            {m.hasTests && <IconListDetails width={24} height={24} strokeWidth={1.5} stroke={'#fff'}
                                                            tooltipText={'Модуль содержит тесты'} />}
                        </Flex>
                    </Flex>,
                    children: <Flex vertical><p> {m.description}</p></Flex>
                }
            })
            setCourseModules(modules)
            setCourse(resp as CourseDto)
        }).catch(() => {

        }).finally(() => {

        })
    }, [])

    return (
        <>
            <Helmet title={course && course.title} />
            {!course && <Loading />}
            {course && <Flex id='courses' gap={32} vertical>


                <CourseOverviewHeader course={course} />
                <Flex gap='24px' className='courses__content'>
                    <Flex vertical className='courses__description-area' gap='16px'>
                        <div>
                            <h2 style={{ fontSize: 40 }}> Описание курса</h2>
                            <div className='courses__description'>{course.description}</div>
                        </div>
                        <div className='courses__details-area'>
                            <h2 style={{ fontSize: 40 }}>Детали курса</h2>
                            <Flex gap='16px' wrap={'wrap'}>
                                <CourseOverviewDetails courseDetail={course.countLessons}
                                                       title='Число уроков' />
                                <CourseOverviewDetails courseDetail={course.level} title='Уровни' />
                                <CourseOverviewDetails courseDetail={course.duration} title='Длительность' />
                                {/*  <CourseOverviewDetails courseDetail={course.views} title='Просмотры' />*/}
                            </Flex>
                        </div>
                    </Flex>
                    <CourseOverviewAuthor course={course} />
                </Flex>
                {courseModules &&
                <Collapse className='courses__collapse' items={courseModules} defaultActiveKey={['1']}
                          onChange={() => {
                          }} size='large' />}
            </Flex>}
        </>
    )
}