import {FC} from 'react'

interface IconPhotoProps {
    className?: string,
    onClick?: () => void
}

export const IconPhoto: FC<IconPhotoProps> = ({className = '', onClick}) => {

    return (
            <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} viewBox="0 0 24 24" fill="none" stroke="#1890ff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" >
                <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
                <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
                <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
            </svg>
    )
}
