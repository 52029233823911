import {Button, Checkbox, Modal} from 'antd'
import {FC, useState} from 'react'
import {NavigationPages} from "../../common/navigation/navigation-pages";
import '../../styles/scss/checkbox/checkbox.scss'
import '../../styles/scss/modal-buying-card/modal-buying-card.scss'
import {GELink} from "../../common/link/GELink";


interface BuyingCardDialogProps {
    isOpen: boolean,
    close: () => void,
    price: number;
    text: string;
    title: string;
    link: () => void
}

const BuyingCardDialog: FC<BuyingCardDialogProps> = ({isOpen, close, text, title, price, link}) => {

    const [checked, setChecked] = useState<boolean>(false)

    const clearAndClose = () => {
        setChecked(false)
        close()
    }

    const onChange = (e: any) => {
        setChecked(e.target.checked)
    };

    return (
        <Modal title={title}
               onCancel={clearAndClose}
               footer={[
                   <Button
                       onClick={link}
                       type='primary'
                       disabled={!checked}
                   >
                       Продолжить
                   </Button>
               ]}
               open={isOpen}>
            <div className='card-price-container'>
                <div className='card__price'>
                    <div className='card__text-price'>{price} ₽</div>
                    <div className='card__price-text'>{text}</div>
                </div>
            </div>

            <Checkbox onChange={onChange} className='checkbox'>
                Я даю согласие на регулярные списания, на обработку персональных данных и принимаю условия
                <GELink className='premium__footer-aferta' to={NavigationPages.AFERTA}> публичной оферты</GELink>
            </Checkbox>


        </Modal>
    )
}

export default BuyingCardDialog
