import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { LoginAndAuthCode } from '../../../../shared/types/api'
import { fetchAuthByCode } from '../../../../store/global/extraReducers'
import Loading from '../../../elements/loading/Loading'
import { useEffect } from 'react'
import { loginLoadingSelector, userSelector } from '../../../../store/global'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'

export const LoginByCode = () => {

    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    const currentUser = useAppSelector(userSelector)

    const loading = useAppSelector(loginLoadingSelector)

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const login = searchParams.get('login')
        const code = searchParams.get('code')
        if (login && code) {
            authBuCode(login, code)

        }
    }, [])

    useEffect(() => {
        if (currentUser && searchParams.get('page') !== 'premium') {
            goToPageNotPremium(`${NavigationPages.PROFILE}/${currentUser.id}`, navigate)
        } else if (currentUser && searchParams.get('page') === 'premium') {
            goToPageNotPremium(`${NavigationPages.PREMIUM}`, navigate)
        }
    }, [currentUser])


    const handleFetchAuthByCode = (user: LoginAndAuthCode) => dispatch(fetchAuthByCode(user))

    const authBuCode = (login: string, code: string) => {
        handleFetchAuthByCode({
            login: login,
            code: code
        })
    }


    return (
        <div id='auth-by-code'>
            <Helmet title='Вход по коду' />
            {loading && <Loading />}
        </div>
    )
}