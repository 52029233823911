import { Modal, Switch } from 'antd'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Pages from '../../../../const/Pages'
import Privileges from '../../../../const/Privileges'
import * as API__DONE_WORDLIST from '../../../../shared/http/done-word-list'
import { setUserRoutine, userSelector } from '../../../../store/global'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import '../../../../styles/scss/done-word-lists/done-word-lists.scss'
import Loading from '../../../elements/loading/Loading'
import NewCommerceUtil from '../../../utils/NewCommerceUtil'
import PrivilegesUtil from '../../../utils/PrivilegesUtil'
import DoneWordsModalDialog from '../../../modalwindows/DoneWordsModalDialog'
import DoneWordItem from './DoneWordItem'
import DoneWordListsSearchComponent from './DoneWordListsSearchComponent'

const DoneWordLists = () => {
    const user = useAppSelector(userSelector)
    const dispatch = useAppDispatch()

    const [doneWordList, setDoneWordList] = useState([])
    const [wordListLoading, setWordListLoading] = useState(true)
    const [disabledButtons, setDisabledButtons] = useState(false)
    const [showActiveWordLists, setShowActiveWordLists] = useState(false)

    const [showWordsModal, setShowWordsModal] = useState(false)
    const [selectedWordListId, setSelectedWordListId] = useState('')
    const changeDisabledButtonsFlag = (value) => {
        setDisabledButtons(value)
    }

    const fillDoneWordList = (data) => {
        setDoneWordList(data)
    }

    const showActiveWordListsFn = (checked) => {
        setShowActiveWordLists(checked)
    }

    const showWords = (id) => {
        setSelectedWordListId(id)
        showWordsModalWindow()
    }

    const showWordsModalWindow = () => {
        setShowWordsModal(true)
    }

    const closeWordsModalWindow = () => {
        setShowWordsModal(false)
    }

    const updateUserIfNew = () => {
        const newUser = NewCommerceUtil.showNewCommerceModal(user, Pages.DONE_WORDLIST)
        if (newUser) {
            dispatch(setUserRoutine(newUser))
        }
    }

    const updateActiveFlag = (id, flagValue) => {
        const newDoneWordList = doneWordList.map((wl) => {
            return wl.id === id ? { ...wl, active: flagValue } : wl
        })
        setDoneWordList(newDoneWordList)
    }

    const updateDoneList = (nwl) => {
        const newDoneWordList = doneWordList.map((wl) => {
            return wl.id === nwl.id ? nwl : wl
        })
        setDoneWordList(newDoneWordList)
    }

    const updatePremiumFlag = (id, flagValue) => {
        const newDoneWordList = doneWordList.map((wl) => {
            return wl.id === id ? { ...wl, premium: flagValue } : wl
        })
        setDoneWordList(newDoneWordList)
    }

    const fetchDoneWordList = (search, level, tag) => {
        API__DONE_WORDLIST.getDoneWordListsPage(search, level, tag)
            .then((data) => {
                fillDoneWordList(data)
            })
            .catch((err) => {
                Modal.error({
                    title: 'Ошибка!',
                    content: 'Не удалось загрузить готовые списки слов'
                })
            })
            .finally(() => {
                setWordListLoading(false)
            })
    }

    const beforeMount = () => {
        updateUserIfNew()
        API__DONE_WORDLIST.getDoneWordLists()
            .then((data) => {
                fillDoneWordList(data)
            })
            .catch((err) => {
                Modal.error({
                    title: 'Ошибка!',
                    content: 'Не удалось загрузить готовые списки слов'
                })
            })
            .finally(() => {
                setWordListLoading(false)
            })
    }

    useEffect(() => {
        beforeMount()
        return () => {
        }
    }, [])

    return (
        <div className='done_word_main'>
            <Helmet title='Готовые списки карточек' />
            {wordListLoading && <Loading />}
            {PrivilegesUtil.hasPrivilege(user, Privileges.EDIT_DONE_WORD_LIST) && (
                <div className='done_word_admin_button'>
                    <Switch onChange={showActiveWordListsFn} />
                    <span> Активные списки </span>
                </div>
            )}
            {/*     Поиск должен работать*/}
            {user && !wordListLoading && <DoneWordListsSearchComponent fetchDoneWordList={fetchDoneWordList} />}


            <div className='done_word_lists'>
                {doneWordList
                    .filter((dwl) => dwl.active || !showActiveWordLists)
                    .map((wordList) =>
                        <DoneWordItem
                            key={wordList.id}
                            wordList={wordList}
                            disabledButtons={disabledButtons}
                            showWords={showWords}
                            changeDisabledButtonsFlag={changeDisabledButtonsFlag}
                            updateActiveFlag={updateActiveFlag}
                            updatePremiumFlag={updatePremiumFlag}
                            updateDoneList={updateDoneList}
                        />
                    )}
            </div>
            <DoneWordsModalDialog
                closeDialog={closeWordsModalWindow}
                selectedWordListId={selectedWordListId}
                open={showWordsModal && selectedWordListId}
            />
        </div>
    )
}

export default DoneWordLists
